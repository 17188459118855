import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from "contexts/authContext";
import SocialMedia from '../../components/Deposits/SocialMedia'

const StepThreetoDeposit = () => {
  const {username} = useAuthContext();


  return  (
    <div className='bg-[#1D1D1D] p-4  lg:p-8 min-h-screen'>
    <article className='w-full mt-5 mx-auto   2xl:max-w-screen-lg '>
    <div className='flex justify-between'>
    <div className=''>
    <h2 className='font-bold text-2xl md:text-3xl text-center '>
        ¡Todo listo! <br /> Muchas gracias {username} por tu depósito.</h2>
        
        <div className='flex flex-col py-4 justify-left items-center '>
        <p className='text-base md:text-xl pt-4  text-[#808080] '>
        Te enviaremos un correo electrónico cuando tu depósito sea confirmado y se cargue a tu cuenta en Imperium. Este paso se realizará lo más pronto posible.
        </p>
        <p className='text-base md:text-xl pt-4 text-[#808080] '>Por el momento puedes explorar nuestras oportunidades.</p>
       <NavLink to='/dashboard/balance'  className='bg-[#CD91FF] px-16 font-semibold mt-10 py-2 text-base md:text-xl rounded-xl text-white '>Ver activos</NavLink>
        </div>
    </div>
    </div>
    <div className=' py-8 text-center'>
    </div>
    <div className='bg-[#949494] py-[.5px]' />
        <div className=' mt-8'>
              <h2 className='font-bold text-2xl md:text-3xl text-center lg:text-left'>¿A dónde transferiré mis cripto?</h2>
            <p className='text-base md:text-xl pt-4 text-[#808080] '>
               A tu cuenta en Imperium. ¡Esto nos permitirá procesar más rápido todas las compras de fracciones que hagas en nuestra App! 
            </p>
        </div>
        <div className='my-8'>
              <h2 className='font-bold text-2xl md:text-3xl text-center lg:text-left'>Síguenos en nuestras redes sociales</h2>
            <SocialMedia/>
        </div>
    </article>
</div>
  )
}

export default StepThreetoDeposit