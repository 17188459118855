import moment from "moment";
import "moment/locale/es";

const daysUntil = (dateToCheckAgainst) => {
  const currentDate = Date.now();
  const comingDate = dateToCheckAgainst;

  if (comingDate < currentDate) {
    return "En breves";
  }
  if (comingDate > currentDate) {
    return new moment().locale("es").to(moment(comingDate));
  }
};

export const handleSetTagStylesByStatusRelease = (project) => {
  if (project.statusCode === 1) {
    return "bg-[#28AD5A] px-4 rounded-3xl";
  } else if (project.statusCode === 2) {
    return "bg-[#FF9600] px-4 rounded-3xl";
  } else if (project.statusCode === 3) {
    return "bg-[#125D74] px-4 rounded-3xl";
  } else if (project.statusCode === 4) {
    return "bg-[#E41E23] px-4 rounded-3xl";
  }
};

export const statusButton = (project) => {
  if (project.statusCode === 1) {
    return "flex items-center justify-center gap-[0.80rem] font-semibold py-1 text-sm mt-4 rounded-md w-full  bg-[#F97575] hover:bg-[#f87e7e] active:bg-[#f97575a9] transition-all ";
  } else if (project.statusCode === 2) {
    return "flex items-center justify-center gap-[0.80rem] font-semibold py-1 text-sm mt-4 rounded-md w-full bg-transparent  border border-[#F97575]  hover:bg-[#f87e7e] active:bg-[#f97575a9] transition-all ";
  } else if (project.statusCode === 4) {
    return "flex items-center justify-center gap-[0.80rem] font-semibold py-1 text-sm mt-4 rounded-md w-full bg-transparent  border border-[#F97575]  hover:bg-[#f87e7e] active:bg-[#f97575a9] transition-all ";
  }
};

export const statusButtonText = (project) => {
  if (project.statusCode === 1) {
    return "Comprar fracciones";
  } else if (project.statusCode === 2) {
    return "Ver detalles";
  } else if (project.statusCode === 3) {
    return "¡Prepárate para su lanzamiento!";
  } else if (project.statusCode === 4) {
    return "Ver detalles";
  }
};

export const handleSetStatusValue = (project) => {
  if (
    project.statusCode === 1 ||
    project.statusCode === 3 ||
    project.statusCode === 4
  ) {
    return project.statusLabel;
  }

  if (project.statusCode === 2) {
    const date = daysUntil(project.releaseDate);
    return date[0].toUpperCase() + date.substring(1);
  }
};

export const buyButton = (project) => {
  return project.statusCode === 1;
};
