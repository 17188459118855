import { AppRouter } from "./router/AppRouter";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {

  

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <AppRouter />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
