//Hooks
import { useEffect,useState, useCallback, useMemo } from 'react'
import React from 'react'
// components
// import UserNotVerified from '../../components/Balance/UserNotVerified'
// import UserVerified from '../../components/Balance/UserIsVerifiex'
// import UserCompleted from '../../components/Balance/UserCompleted'
import UserLogs from '../../components/Balance/UserLogs'
import { NavLink } from 'react-router-dom'


//utils and helpers
import paginate from '../../helpers/paginate'
import LoadingDot from "../ProjectsPage/Loading";
import { getPropertyData } from '../../helpers/propertiesProject'

//UI
// import Grafica from '../../Assets/Imgs/grafica.svg'
import HowIsBeingCalculated from '../../components/Balance/HowIsBeingCalculated'

//Cookies
import { useAuthContext } from "../../contexts/authContext";
import Cookies from "universal-cookie"

//  Icons
import { ReactComponent as Loss } from "../../Assets/Icons/Loss.svg";
import { ReactComponent as Profit } from "../../Assets/Icons/Profit.svg";

// API requests
import { handleGetAllProjects } from '../../api/getProjects'
import { getFranctionsByUser } from '../../api/getUserFractions'



const MY_AUTH_USER_ID = 'idUserImperium'
const MY_AUTH_TOKEN = "tokenImperium";



const Balance = () => {
const cookies = new Cookies();

// const [userVerified, setUserVerified] = useState(false)
// const [userHasDeposit, setUserHasDeposit] = useState(false)

const {logout} = useAuthContext()
const [loading, setLoading] = useState(true);
//UserInfo
const [userID] = useState(cookies.get(MY_AUTH_USER_ID) ?? false)
const [token] = useState(cookies.get(MY_AUTH_TOKEN) ?? false)
const [userBalance, setUserBalance] = useState('')
const [userLog, setUserLog] = useState([])
const [userSummary, setUserSummary] = useState(null)
//Pagination
const [page,setPage] = useState(0);
const [dataLog, setDataLog] = useState([])
const [setLogEmpty ] = useState(false)

console.log('userLog', userLog)

const options = useMemo(() => ({
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
}), [token]);


const baseUrl = process.env.REACT_APP_API_URL;

const handleGetBalanceByUser = useCallback(async () => {

   const userUrl =`${baseUrl}/v1/fundsBalance/?userId=${userID}` //UserID de las cookies
  
  try {
    
    const response = await fetch(userUrl, options)
    console.log(token)
    console.log(userID)
    if(response.status === 401){
      console.log(response.status)
      logout()
    }
    const data = await response.json()
    console.log(data);

    setUserBalance(data?.fundsBalanceByUserId?.amount)
    
  } catch (error) {
    console.log(error);
    setUserBalance(null)
  }
}, [baseUrl,logout,options,token,userID]);


const handleGetFranctionsByUser = useCallback(async () => {
  
  const userFractions = await getFranctionsByUser(userID, token)

  if (!userFractions) {
    setLogEmpty(true)
    setUserLog([])
    setUserSummary(null)
    return
  }

  const userPurchasedFractions = userFractions.data.grid
  const userSummaryFractions = userFractions.data.summary

  setUserSummary(userSummaryFractions)

  const projects = await handleGetAllProjects(token)

  const userFractionsDetailed = userPurchasedFractions.map((item) => {

    const project = projects.find(project => project._id === item.projectID)
    const projectName = getPropertyData( project.properties, 'Name')
    const image = project.image

    return {...item, name: projectName, image}
    
  })

  console.log('userPurchasedFractions', userPurchasedFractions)

  setUserLog(paginate(userFractionsDetailed))
  
}, [token, userID,setLogEmpty])


useEffect(()=>{

  const initialize = async () => {
    setLoading(true)
    window.scrollTo(0, 0)
    await handleGetBalanceByUser()
    await handleGetFranctionsByUser()
    
    setLoading(false)
  }

  initialize()

},[handleGetBalanceByUser,handleGetFranctionsByUser])

useEffect(()=>{
  if(loading) return
  setDataLog(userLog[page])
},[loading,page,userLog])


const handlePage = (index)=>{
  setPage(index)
}


const nextPage = () =>{
  setPage((oldPage) =>{
    let nextPage = oldPage + 1
    if(nextPage > userLog.length -1){
      nextPage = 0
    }
    return nextPage
  })
}

const prevPage = () =>{
  setPage((oldPage) =>{
    let prevPage = oldPage - 1
    if(prevPage < 0){
      prevPage = userLog.length - 1
    }
    return prevPage
  })
}

return (
<div className='text-white lg:p-4 min-h-screen bg-[#1C1B2D]'>
  <div className='p-4 2xl:max-w-7xl  mx-auto'>
    <div className='grid grid-cols-2  '>
      <div className='flex flex-col justify-evenly gap-1'>
        <h1 className='text-3xl font-bold'>¡Bienvenido de nuevo!</h1>
        <p className='text-lg font-normal'>Valor del portafolio</p>
        {/* <p className='text-3xl font-semibold'>$ {userSummary ? (userSummary.totalValue + userBalance)?.toFixed(2) : '0.00'}</p> */}
        <p className='text-3xl font-semibold'>$ {userSummary ? (userSummary.totalValue + userBalance)?.toFixed(2) : (userBalance || 0).toFixed(2) }</p>
      </div>
    </div>

    {/* ====== GRAFICA ===== */}

      {
        // (userBalance && userSummary) && (
        // (userBalance || userSummary) && (
          <>
          <h2 className='text-3xl mt-8  font-bold'>Resumen</h2>

          <div className='mt-4 bg-[#0D0D0D] rounded-2xl  flex flex-col'>
            <div className='grid grid-cols-1  p-2 place-items-center gap-2 md:gap-4  text-center  font-normal md:grid-cols-3'>
              <div>
                <p className='text-[#A7A6A6]'>Efectivo disponible</p>
                <p>$ {userBalance ? userBalance?.toFixed(2) : '0.00'}</p>
              </div>
              <div>
                <p className='text-[#A7A6A6]'>Total invertido</p>
                {/* <p className='text-lg'>$ {userSummary.totalInvestment?.toFixed(2)}</p> */}
                <p className='text-lg'>{`$ ${userSummary?.totalInvestment?.toFixed(2) || '0.00'}`}</p>
                {/* <p className='text-lg'>{`$ ${userSummary?.totalInvestment?.toFixed(2)}` || '-'}</p> */}
              </div>
              <div>
                <p className='text-[#A7A6A6]'>Ganancia/Perdida</p>
                {
                  userSummary ? (
                    <div className='flex items-center gap-1 text-lg'>
                      {
                        userSummary.totalReturn > 0 && <Profit/>
                      }
                      {
                        // userSummary.totalReturn >= 0 && <p className={ userSummary.totalReturn > 0 ? "text-[#68E365]" : "text-[#fff]"}>{`$ ${userSummary.totalReturn?.toFixed(2)} (${userSummary.totalReturnPercent?.toFixed(2)}%)`}</p>
                        userSummary.totalReturn >= 0 && <p className={ userSummary.totalReturn > 0 ? "text-[#68E365]" : "text-[#fff]"}>{`$ ${userSummary?.totalReturn?.toFixed(2)} (${userSummary?.totalReturnPercent?.toFixed(2) || '0.00'}%)`}</p>
                      }
                     

                      {
                        userSummary.totalReturn < 0 && <Loss/>
                      }
                      {
                        userSummary.totalReturn < 0 && <p className="text-[#F1494E]">{`$ ${userSummary.totalReturn?.toFixed(2)} (${userSummary?.totalReturnPercent?.toFixed(2)}%)`}</p>
                      }

                    </div>
                  ) : (
                    <div>
                       <p>$ 0.00 </p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
    </>

        // )
      }
      

    {/* ====== ACTIVOS ===== */}

    <section className='mt-8 py-4'>
      <HowIsBeingCalculated/>
      {loading ? (
        <div className='mt-36'>
          <LoadingDot />
        </div>
      ) : (
        <>
        {userLog.length === 0 ? <section>
        <div className='bg-[#0D0D0D] mt-4 flex justify-center text-center rounded-3xl p-4'>
          <div>
            <h2 className='py-2 text-xl'>Tu portafolio esta vacío</h2>
            <p className='text-[#959595] text-lg py-2 '>Comienza explorando activos digitales</p>
            <div className='my-4'>
              <NavLink to='/' className='text-black font-semibold text-base  py-1 px-8 bg-[#68E365] rounded-lg '>Explorar
              </NavLink>
            </div>
          </div>
        </div>
      </section> :   
      <>
      <UserLogs fractionLogs={dataLog} />
        <section className='flex justify-center flex-wrap mt-8 mx-auto  text-lg'>
          <button className='mx-4' onClick={prevPage}>
           
          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
          </button>
          {userLog?.map((item, index)=>{
            return <button key={index} onClick={()=> handlePage(index)} className={`w-8 h-8 bg-custom-pink border-transparent rounded-md x cursor-pointer m-1 transition-all hover:bg-[#FF5262]  ${index === page ? 'bg-[#FF5262] text-white' : null}` }>
                {index + 1}
              </button>
          })}
           <button className='mx-4 ' onClick={nextPage}>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
            
          </button>
        </section> 
        </>
        }
        </>
      )}

    </section>

      {/* <section className='bg-[#0D0D0D]  h-auto my-auto rounded-3xl'>

        {userVerified ?
        <UserVerified /> :
        <UserNotVerified /> }
      </section> */}
{/* 
    <div className='grid-flow-row grid grid-rows-3 '>
      <section className='my-auto'>
        <div className='bg-[#0D0D0D] rounded-3xl p-6 grid grid-cols-3 place-items-center'>
          <div>
            <h3 className='text-xl text-[#959595]'>Efectivo disponible vacío </h3>
            <div className='my-2 flex justify-center'>
              <a href="#" className='text-black font-semibold text-sm py-2 px-4 bg-[#F97575] rounded-xl '> Depositar
                fondos</a>
            </div>
          </div>
          <div>
            <h3 className='text-xl text-[#959595]'>Total invertido vacío</h3>
            <div className='my-2 flex justify-center'>
              <a href="#" className='text-black font-semibold text-sm py-2 px-4 bg-[#F97575] rounded-xl '> Explorar
                activos</a>
            </div>
          </div>
          <div>
            <h3 className='text-xl text-[#959595]'>Ganancia/Pérdida vacía </h3>
            <div className='my-2 flex justify-center'>
              <a href="#" className='text-black font-semibold text-sm py-2 px-12 bg-[#F97575] rounded-xl '>Ganar</a>
            </div>
          </div>
        </div>
      </section>
    </div> */}

  </div>

</div>
)
}

export default Balance