import { useState, useEffect } from "react";

import Cookies from "universal-cookie"
import {  NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import faq from './faq.js'
import {useForm} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Events,  scrollSpy } from 'react-scroll'
import {buyButton, handleSetTagStylesByStatusRelease, handleSetStatusValue} from '../../pages/ProjectsPage/status'
import "./ProjectDetails.scss"

import { ReactComponent as Dart } from "../../Assets/Icons/Dart.svg";
import { ReactComponent as Alert } from "../../Assets/Icons/Alert.svg";


import Discord from "../../Assets/Icons/Discord";
import Instagram from "../../Assets/Icons/Instagram";
import Website from "../../Assets/Icons/Website";
import Twitter from "../../Assets/Icons/Twitter";
// import Share from "../../Assets/Icons/Share";
import stats from "../../Assets/Imgs/stats.png";
import stats2 from "../../Assets/Imgs/stats2.png";
import warning from '../../Assets/Imgs/warning.png'

import SingleQuestion from "./SingleQuestion.jsx";
import EvolutionTimeline from './EvolutionTimeline'
import preview from '../../Assets/Imgs/preview.png'
import LoaderButtonFractions from '../../components/Loader/LoaderButtonFractions' ;
import { getPropertyData, getPropertyLink } from "../../helpers/propertiesProject";
import { ReactComponent as Cart } from "../../Assets/Icons/Cart.svg";




const MY_AUTH_USER_ID = 'idUserImperium'
const MY_AUTH_TOKEN = "tokenImperium";

const ProjectDetails = ({setDetailInfo}) => {

// const releaseStatus = setDetailInfo.releaseStatus?.statusLabel
const navigate = useNavigate()
const { isAuthenticated: isLoggedIn, logout } = useAuthContext();
const cookies = new Cookies();
const [user] =  useState(cookies.get(MY_AUTH_USER_ID) ?? false)
const [token] = useState(cookies.get(MY_AUTH_TOKEN) ?? false)
const {email} = useAuthContext();

// User ID - Balance
const [userIdBalance, setUserIdBalance] = useState()
const baseUrl = process.env.REACT_APP_API_URL;

const userBalanceUrl = `${baseUrl}/v1/fundsBalance/?userId=${user}`


const [isSubmitting, setIsSubmitting] = useState(false);
const [isSubmited, setIsSubmited] = useState(false)
const [fetchError, SetFetchError] = useState(false)
const [totalAmount, setTotalAmount] = useState()
const [depositButton] = useState(false)
const projectID = setDetailInfo?._id
const [remainingFractions] = useState(setDetailInfo.remainingFractions)





useEffect(() => {

    const scrolling = async () => {

        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
          });
      
          Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
          });
      
          scrollSpy.update();
      
    }

    scrolling()

  }, []);










useEffect(()=>{
isLoggedIn && fetch(userBalanceUrl,  {
  method: 'GET',
  headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
})
.then((response) => {
        if(response.status === 401){
        logout()
    }
    return response.json()
}).then((data) => {
    console.log(data);
return setUserIdBalance((data?.fundsBalanceByUserId?.amount)?.toFixed(2))
    
});
},[isSubmited,isLoggedIn,logout,token,userBalanceUrl])


const schema = yup.object().shape({
    numberOfFractions: yup.number()
    .integer('Ingresa un valor de caracter entero')
    .positive('Ingresa una cantidad positiva')
    .typeError('Ingresa un número por favor')
    .required('Ingresa una cantidad por favor')
    .test(
    "checkAvailableFractions",
    `Solo existen ${remainingFractions} fracciones disponibles de este activo. Reduce la cantidad de fracciones a comprar`,
    
    (value) => {
        return value <= remainingFractions
    }

    ).test(
    "checkAvailableBalance",
    `No tienes fondos suficientes, reduce la cantidad de fraccion a comprar o deposita fondos`,
    (value) => {
        const pricePerFraction = setDetailInfo?.properties?.find( propertyName => propertyName.propertyName === 'Price per fraction')?.propertyObject?.data

       if(userIdBalance  >=  value * pricePerFraction ){
        return true
    } else {
        return false
    }
    }
    ).test(
        "totalAmountBalance",
        (value) =>{
            const pricePerFraction = setDetailInfo?.properties?.find( propertyName => propertyName.propertyName === 'Price per fraction')?.propertyObject?.data
            return !setTotalAmount( value * pricePerFraction )
        }
    )
})



const [mainTab] = useState([
    "Información del activo",
    // "Estadísticas por activo",
    "Preguntas frecuentes",
    ]);
    
const [questions] = useState(faq)

const [secondaryTab] = useState([
'Tiempo Real',
'Hace 7 días',
'Hace 14 días'
])
const [renderTab, setRenderTab] = useState(0);
const [value, setValue] = useState(0);
const [secondaryValue, setSecondaryValue] = useState(0);

// const resumenSec = useRef(null)
// const equipoSec = useRef(null)
// const beneficiosSec = useRef(null)
// const translate = useRef(null)
const [toggleModal, setToggleModal] = useState(false)

const { _id:id,image,} = setDetailInfo


const pricePerFraction = getPropertyData(setDetailInfo?.properties, 'Price per fraction')

const oneLiner = getPropertyData(setDetailInfo?.properties, 'One liner')

const evolutionItems = getPropertyData(setDetailInfo?.properties, 'Evolution items').sort((a,b) => a.index - b.index)

const evolutionText = getPropertyData(setDetailInfo?.properties, 'Evolution')
const name = getPropertyData(setDetailInfo?.properties, 'Name')

const riesgos = getPropertyData(setDetailInfo?.properties, 'Risks')
const oportunidad = getPropertyData(setDetailInfo?.properties, 'Opportunity')
const risksItems = getPropertyData(setDetailInfo?.properties, 'Benefits items')
const opportunityItems = getPropertyData(setDetailInfo?.properties, 'Resume items')

const releaseStatus = setDetailInfo?.releaseStatus

const linkWebsite = getPropertyLink(setDetailInfo?.properties, 'Link Website')
const linkDiscord = getPropertyLink(setDetailInfo?.properties, 'Link Discord')
const linkTwitter = getPropertyLink(setDetailInfo?.properties, 'Link Twitter')
const linkInstagram = getPropertyLink(setDetailInfo?.properties, 'Link Instagram')

useEffect(() => {
    
    if (releaseStatus.statusCode === 3) {
        navigate('/')
    }
}, [navigate,releaseStatus.statusCode]);


// ====== Project MODAL Fractions =======
const handleModal = () => {
    setToggleModal(!toggleModal)
    if (isLoggedIn === false){
            navigate('/auth/login')
    }  
}

const handleDepositButton = () =>{
    navigate('/dashboard/no-funds')
}


const {register, handleSubmit, formState:{errors}} = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    
}, )

        const fractionsUrl =  `${baseUrl}/v1/fractionsPurchase`

    const onSubmit = async (data) => {

            if (!isLoggedIn){
            return navigate('/auth/login')
        }  

        const numberOfFractionEmail = data.numberOfFractions
        setIsSubmitting(true)

        const requestFractions = {
            userID: user,
            amount: data.numberOfFractions,
            projectID: projectID,
            fractionPrice: pricePerFraction,
        }

        try {
            const responseFractions = await fetch(fractionsUrl, {
                method: 'POST', 
                body: JSON.stringify(requestFractions), 
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
            }})
            
            const response = responseFractions
            const data = await responseFractions.json()
           
        
            console.log(data);
            //todo: ver y controlar si se hizo bien
   

            // status code 200 Si Todo es OK
            if (response.status === 200) {
                const purchaseUrl = process.env.REACT_APP_EMAIL_URL


                
                
            const request = {
                destination: email,
                emailCode: 'purchaseFraction',
                payload: `${numberOfFractionEmail} fracciones de ${name}`
            }

            const responseSendEmailPurchase = await fetch(purchaseUrl, {
                method: 'POST', 
                body: JSON.stringify(request),
                headers:{ 
                Accept: "application/json",
                 "Content-Type": "application/json;charset=utf-8",
                key: process.env.REACT_APP_EMAIL_KEY,
                
            }})

            await responseSendEmailPurchase.json()
        



            setTimeout(() => {
                setIsSubmitting(false)
                setIsSubmited(true)
            }, 2000)
            } 
            
            //Status code 406 'Not Accepted'
            if(response.status === 406) {
            setTimeout(() => {
                setIsSubmitting(false)
                SetFetchError(true)
            }, 2000)
            }
            
    
        } catch (e) {
            console.log('error, ', e ,e.message)
            setTimeout(() => {
                setIsSubmitting(false)
            }, 2000)
        }
    }


    return (
        // <article key={id} className="px-4 pt-32 pb-14 min-h-screen">
        // <article key={id} className="px-4 md:px-16 pt-32 pb-14 min-h-screen max-w-[80.125rem] mx-auto">
        <article key={id} className="px-4 pt-32 pb-14 min-h-screen max-w-[80.125rem] mx-auto">
        <div className="text-white text-lg">
                <NavLink to='/' >« Volver atrás</NavLink>
            </div>
            {/* <div className="flex flex-col xl:flex-row gap-8 mt-4 "> */}
            <div className="flex flex-col xl:flex-row  gap-8 mt-4 ">
            <div className="rounded-xl   text-white text-sm max-w-[33.75rem] md:h-[33.5rem] mx-auto  border-[#FBF7F7] border-[0.0625rem] py-2 bg-[#30384D] lg:text-base  ">
                {
                releaseStatus && (
                    <div className={`${handleSetTagStylesByStatusRelease(releaseStatus)} m-2 px-4 max-w-fit`}>
                        <p>{handleSetStatusValue(releaseStatus)}</p>
                    </div>
                ) 
                }

            <img src={image || preview} alt={name} className="w-full min-w-[17.5rem] object-cover max-w-[25rem] md:min-h-[26.875rem] max-h-[26.875rem] md:min-w-[25rem]  py-2" />
            <div className="flex text-sm justify-between px-4 lg:text-base  ">
                <p>Precio por fracción</p>
                <p>Fracciones disponibles</p>
            </div>
            <div className="flex text-sm justify-between px-4  lg:text-base ">
                <p>{releaseStatus.statusCode === 4 ? '-' : `$${pricePerFraction}`}</p>
                <p>{releaseStatus.statusCode === 4 ? '-' : remainingFractions }</p>
            </div>
            {/* <div className="px-4 py-1 relative">
                    <div className="bg-[#108F5E] absolute  rounded-3xl w-1/3 mt-2 h-2 "></div>
                <div className="bg-[#d9d9d9] rounded-3xl w-full my-2 h-2 mx-auto">
                </div>
            </div>
            <p className="px-4 text-white text-sm  lg:text-base">
                25% vendidos, 2500 inversores
            </p> */}
            
            
        </div>
        <section className="mt-8 flex-col text-xl text-center lg:mt-0">
            <div className="flex flex-col justify-center xl:flex-row items-center lg:justify-between">
                <div className="2xl:max-w-2xl"><h1 className="font-semibold  text-ellipsis overflow-hidden lg:text-[1.5rem]">{`${name}`}</h1></div>
                <ul className="flex gap-6  justify-evenly py-4">
                    
                    {/* linkWebsite */}
                    {/* <li>
                        <a target='blank' >
                            <Website />
                        </a>
                    </li> */}
                    {
                        linkWebsite && (
                            <li>
                                <a  target='blank' href={linkWebsite}>
                                    <Website />
                                </a>
                            </li>
                        )
                    }
                    {
                        linkTwitter && (
                            <li>
                                <a  target='blank' href={linkTwitter}>
                                    <Twitter />
                                </a>
                            </li>
                        )
                    }
                    {
                        linkDiscord && (
                            <li>
                                <a  target='blank' href={linkDiscord}>
                                    <Discord />
                                </a>
                            </li>
                        )
                    }
                    {
                        linkInstagram && (
                            <li>
                                <a  target='blank' href={linkInstagram}>
                                    <Instagram />
                                </a>
                            </li>
                        )
                    }
                    {/* <li className="border-l-[1px] border-white pl-3">
                        <a target='blank' href="#">
                            <Share />
                        </a>
                    </li> */}
                </ul>
            </div>
            <div className="flex flex-col  text-white  text-base py-2 text-left">
                <p className="py-4 lg:text-lg">
                   {`${oneLiner} `}
                </p>
                <h3 className="font-semibold lg:text-lg">
                    Precio por fracción / Fracciones disponibles
                </h3>
                <div className="flex  justify-left gap-2 font-semibold text-base">
                    <p>{releaseStatus.statusCode === 4 ? '-' : `$${pricePerFraction}`} / </p>
                    <p>{releaseStatus.statusCode === 4 ? '-' : remainingFractions}</p>
                </div>
                <h3 className="font-semibold mt-6 lg:text-lg">Evolución</h3>
                <p className="font-light ">
                   {evolutionText}
                </p>
                <EvolutionTimeline items={evolutionItems}/>
                {buyButton(releaseStatus)  && <button  onClick={handleModal} className="flex items-center justify-center gap-[0.80rem] hover:bg-[#f87e7e] px-8 mt-4 py-2 rounded-lg font-semibold bg-[#F97575] lg:py-4 transition-all">
                            <Cart
                            className="text-custom-gray-light--input w-4 h-4 cursor-pointer"
                            /> 
                       
                        Comprar fracciones
                    </button>
                  }

            {/* ======== MODAL ========  */}
            
        <div tabIndex="-1" 
        
        className={`overflow-y-auto overflow-x-hidden p-2  fixed top-0 right-0 left-0 z-50 w-full animate__animated animate__fadeIn animate__faster  md:inset-0 h-modal md:h-full bg-[rgba(40,40,40,0.5)] justify-center items-center ${!toggleModal ? 'hidden' : null}`}>
        <div className="relative mx-auto mt-40 w-full max-w-md h-full md:h-auto">
        <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" onClick={handleModal} 
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            >
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
            <span className="sr-only">Close modal</span>
            </button>
            <div className="py-4 px-6 rounded-t border-b dark:border-gray-600">
            <h3 className="text-lg  pt-2 font-semibold text-gray-900 lg:text-xl dark:text-white">
                Completa la compra
            </h3>
            
             {/* {errors.numberOfFractions && (<div className="text-white mt-2 p-2 bg-custom-red rounded-lg text-lg">{errors.numberOfFractions?.message}</div>) } */}
             { isSubmited ? (<div className="text-white text-center mt-2 p-2 bg-green-400 rounded-lg text-lg">
                ¡Felicitaciones! Tu compra fue exitosa
             </div>) : null }
             {isSubmited ? <p className="text-black text-center mt-4">Puedes revisar tu portafolio desde el dashboard</p> : null}
            </div>
                
                {/* ======== BUY FRACTIONS ========  */}

            <form onSubmit={handleSubmit(onSubmit)} className="px-6 py-4 ">
                <div className="flex">
                    <p className="text-custom-gray-light font-medium">Tu saldo disponible:</p>
                    <p className="text-custom-gray-light font-semibold px-2">{userIdBalance ? `$${userIdBalance}` : '$0.00'}</p>
                </div>
                 <hr className="my-2 border-t-[0.0625rem] border-[#9492A0] opacity-[0.6] " />
                <h4 className="text-custom-pink text-lg font-semibold">{name}</h4>
                <div className="flex justify-between pt-2 gap-4 ">
                    <div className="hidden sm:flex">
                        <img src={image || preview} alt={name} className="object-cover w-full h-full" />
                    </div>
                    <div className=" flex flex-col sm:px-4  gap-4 text-base font-medium text-custom-gray-light whitespace-nowrap">
                        <p>Precio total del activo</p>
                        <p>Fracciones disponibles</p>
                    </div>
                    <div>
                        <div className=" flex flex-col text-center font-semibold gap-4 text-base text-custom-gray-light whitespace-nowrap">
                        <p>${pricePerFraction}</p>
                        <p>{remainingFractions}</p>
                    </div>
                    </div>
                </div>
                <hr className="mt-2 border-t-[0.0625rem] border-[#9492A0] opacity-[0.6] " />
                <div className="flex justify-between mt-4 text-base  ">
                    <div >
                        <h4 className="text-custom-gray-light font-medium">Precio por fracción</h4>
                    </div>
                    <div className="text-custom-gray-light font-semibold  ">
                        ${pricePerFraction}
                    </div>
                </div>
                <hr className="mt-2 border-t-[0.0625rem] border-[#9492A0] opacity-[0.6]"  />
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between items-center mt-4 text-base ">
                    <div>
                        <h4 className="text-custom-gray-light font-medium whitespace-nowrap">Cantidad de fracciones</h4>
                    </div>
                    {/* <input type='number' name="numberOfFractions" placeholder="Completa aqui" {...register('numberOfFractions', {required: true})} className="text-custom-gray-light text-center text-lg" /> */}
                    <input type='text' min='0' disabled={isSubmited ? true : false} placeholder="Completa aquí" {...register('numberOfFractions')} className="text-custom-gray-light w-[8.75rem] h-8  text-right  text-lg  focus:ring-custom-pink focus:border-custom-pink focus:rounded" />
                </div>
                <hr className="mt-2 border-t-[0.0625rem] border-[#9492A0] opacity-[0.6]" />
                <div className="flex justify-between mt-4 text-base ">
                        <div>
                            <p className="text-custom-gray-light font-medium">Total</p>
                        </div>
                        <div>
                            <p  className="text-custom-gray-light font-semibold ">{totalAmount ? `$${totalAmount}`  : '$0'}</p>
                        </div>
                
                </div>
                {fetchError && <div className="text-[#D93F21] font-medium text-left mt-2 py-2  text-base">No quedan fracciones disponibles  </div> }
                {errors.numberOfFractions && (<div className="text-[#D93F21] font-medium text-left mt-2 py-2  text-base">{errors.numberOfFractions?.message} </div>) }
                {errors.numberOfFractions?.message.includes('No tienes fondos suficientes') && <div className="flex justify-center pt-4">
                <NavLink to='/dashboard/deposit' className="font-anek-tamil font-semibold  h-8 sm:h-10 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-pink w-full hover:bg-custom-pink rounded-lg px-6 py-1 text-center">Depositar fondos</NavLink>
             </div>}
               {!fetchError ?  <div className="mt-4">
                    <LoaderButtonFractions  
                    isSubmitting={isSubmitting}
                    isDisabled={isSubmitting}
                    isSubmited={isSubmited}
                    text={isSubmited ? "Ver Balance" : "Confirmar"}  />
                </div> : <div className="flex justify-center mt-4 ">
          <NavLink
            to="/"
            className=" disabled:hover:bg-custom-pink  disabled:cursor-not-allowed disabled:opacity-75 font-anek-tamil font-semibold  h-10 sm:h-12 lg:h-12 xl:h-12 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-pink w-full hover:bg-custom-pink  rounded-lg px-6 py-2 text-center"
          >
            Explorar otros proyectos
          </NavLink>
        </div> }
                <div className="mt-4">
                    {depositButton ? 
                    <button onClick={handleDepositButton}  className=" font-anek-tamil font-semibold  h-6 sm:h-12 lg:h-12 xl:h-12 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-custom-pink border-2 border-custom-pink bg-white w-full hover:bg-custom-pink hover:text-white transition-all  rounded-lg px-6 py-2 text-center">Depositar fondos</button> : null}
                </div>
                </form>


        </div>
        </div>
    </div>
            </div>
        </section>
        </div>
  
        { releaseStatus.statusCode === 4 ? null : 
            <section className="flex flex-col rounded overflow-hidden md:flex-row text-sm mt-4 border-[0.125rem] border-[#FFD25E] text-white">
            <div className="bg-[#FFD25E] border border-[#FFD25E]  rounded-r-none p-2 md:p-4   flex justify-center md:justify-start items-center ">
                <img src={warning} alt="warning" className="w-[4rem].5rem] md:w-[9.25rem]   " />
            </div>
            <div className="py-4 px-4">
                    <p className="text-justify"> <strong className="font-bold"> AVISO: </strong> Imperium ejecutará la compra del activo (NFT) cuando el 95% o más, de las fracciones disponibles hayan sido compradas. Dichas fracciones representan derechos económicos sobre el activo (NFT). Por el momento, las fracciones tendrán un periodo de inmovilización de 6 meses, por lo que no se podrán ejecutar ventas inmediatas hasta dicha fecha, sin embargo, Imperium hará su mejor esfuerzo para facilitar las ventas de fracciones a través de terceros.</p>
            </div>
        </section>
        }
        <section className="text-white mt-4 md:flex lg:gap-8 ">
                {mainTab.map((tabs, index) => {
                return (
                <button key={index} onClick={()=> {
                    setRenderTab(index);
                    setValue(index);
                    }}
                    className={`${
                    index === value && "project-tab"
                    } flex justify-center mx-auto py-1 my-8 lg:mx-0 lg:text-xl`}
                    >
                    {tabs}
                </button>
                );
                })}
            </section>
            <section className="bg-[#202028] h-full flex flex-col my-5 p-8 text-white rounded-xl  ">
                    {renderTab === 0 ? (
                    <article className="block__section">

                                <div className="block__sub-section">
                                    <div className="block__title">
                                        <span><Dart className="w-6 h-6"/></span> 
                                        <h3 className="font-semibold lg:text-xl">¿Por qué se considera una buena oportunidad para invertir?</h3>
                                    </div>
                               
                                    <p className="whitespace-pre-wrap text-sm text-left font-light text-[#FBF7F7] lg:text-base">
                                    { oportunidad && oportunidad}
                                    </p>
                                    {
                                            opportunityItems?.length !== 0  &&  (
                                                
                                                <div className="grid-photos-container">
                                                    {/* {
                                                        opportunityItems.map((img, i) => <img src={img.photoItem} key={i} className="object-cover w-full h-full max-h-32 rounded-2xl drop-shadow-[2px_4px_4px_rgba(53,53,64,1)]" alt={`opportunityItems_${i}`} />)
                                                    } */}

                                                    {
                                                    
                                                    opportunityItems.map((img, i) => (
                                                            <div key={i} className="h-32">
                                                                <img src={img.photoItem} className="object-cover w-full h-full rounded-2xl drop-shadow-[2px_4px_4px_rgba(53,53,64,1)]" alt={`risksItems_${i}`} />
                                                            </div>
                                                        ))
                                                    }
                                                

                                                
                                                </div>
                                           )
                                    }
                                    
                                </div>

                                <div className="block__sub-section" >

                                    <div className="block__title">
                                    <span><Alert className="w-[1.4rem] h-[1.4rem]"/></span> 
                                        <h3 className="font-semibold lg:text-xl">¿Cuáles son los riesgos involucrados al invertir?</h3>
                                    </div>
                                    <p className="whitespace-pre-wrap text-sm text-left font-light text-[#FBF7F7] lg:text-base">
                                    {riesgos && riesgos}
                                    </p>

                                        {
                                            risksItems?.length !== 0  &&  (
                                                
                                                <div className="grid-photos-container">
                                                    {
                                                        risksItems.map((img, i) => (
                                                            <div key={i} className="h-32">
                                                                <img src={img.photoItem} className="object-cover w-full h-full rounded-2xl drop-shadow-[2px_4px_4px_rgba(53,53,64,1)]" alt={`risksItems_${i}`} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                           )
                                        }
                               
                                </div>
                    </article>
                    ) : null}

                {renderTab === 2 ? <article>
                    <div className="flex justify-around">
                            {secondaryTab.map((tabs, index) => {
                            return (
                            <button key={index} className={`${ index === secondaryValue && "project-secondary-tab" }
                                text-sm cursor-pointer`} type="button" onClick={()=> {
                                setSecondaryValue(index);                
                                }}
                                >
                                {tabs}
                            </button>
                            );
                            })}
                    </div>
                    <div className="relative">
                        <img src={stats} alt="" className=" relative mt-8 mx-auto" />
                        <img src={stats2} alt=""  className="absolute mx-auto top-5 left-0 right-0 p-4 h-auto "/>
                    </div>
                </article> : null}
                {renderTab === 1 ? <section >
                    <div className="flex flex-col justify-start min-h-screen gap-8 mt-8 mx-auto ">
                        {questions.map((question) =>{
                            return <SingleQuestion key={question.id} {...question}/>
                        })}
                    </div>

                </section> : null}
            </section>
    </article>
    );

}

export default ProjectDetails






