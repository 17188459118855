import { Ring } from "@uiball/loaders";

const LoadingDot = () => {
  return (
    <div className="flex justify-center mt-10">
      <Ring 
            size={50}
            speed={2} 
            color="#F97575"
        />
    </div>
  )
}

export default LoadingDot