import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { ReactComponent as User } from "../../../Assets/Icons/User.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Logo from "../../../components/Logo/Logo";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";

const baseUrl = process.env.REACT_APP_API_URL;

const schema = yup.object().shape({
  email: yup
    .string()
    .trim("Evita colocar espacios innecesarios")
    .strict(true)
    .email("Correo electrónico inválido")
    .required("Ingresa un correo electrónico")
    .test(
      "checkUserNameUnique",
      "Este correo electrónico no se encuentra registrado.",
      (userEmail) =>
        // `${baseUrl}/v1/user/account?userEmail=${email?.toLowerCase()?.trim()}`
        fetch(
          `${baseUrl}/v1/user/account?userEmail=${userEmail
            .toLowerCase()
            .trim()}`
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("endpoint checking, ", data, !!data.dataUser);
            return !!data.dataUser;
          })
          .catch(() => {
            console.log("Server failed. Endpoint check if email exist");
            return false;
          })
    ),
});

const RecoveryAccountPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsgServices, setErrorMsgServices] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = async ({ email }) => {
    setIsSubmitting(true);
    setErrorMsgServices(null);

    try {
      const url = `${baseUrl}/v1/auth/forgot-password`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ userEmail: email }),
      });

      const data = await response.json();

      setTimeout(() => {
        setIsSubmitting(false);

        if (data.error === "Email Invalid") {
          setErrorMsgServices(
            "Este correo se ha creado con un proveedor externo"
          );
        } else {
          navigate(`/reset-password/${email}`);
        }
      }, 2000);
    } catch (error) {
      console.log("error, ", error);
      setTimeout(() => {
        setIsSubmitting(false);
        setErrorMsgServices("Ha ocurrido un error, intenta de nuevo");
      }, 1000);
    }

    setTimeout(() => {
      setErrorMsgServices(null);
      setIsSubmitting(false);
    }, 5000);
  };

  return (
    <div className="min-h-screen flex">
      {/* -----------------------------   Start image section  ----------------------------*/}
      <WelcomeLoginImage />

      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}

      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-2/5">
          <h1 className="md:w-3/4 lg:w-full text-3xl md:text-4xl font-extrabold mb-6 font-anek-tamil">
            Recuperar <br className="lg:hidden" /> contraseña
          </h1>

          {/* -----------------------------   Start form section  ----------------------------*/}
          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2 lg:mb-4 ">
              {errors.email && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.email?.message}
                </div>
              )}

              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <User className="text-red-500  w-7 h-7" />
                </div>

                <input
                  {...register("email")}
                  type="text"
                  id="username"
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input ${
                    // !errors.username && touchedFields.username
                    !errors.email ? "text-custom-purple" : "text-white"
                  } focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Correo electrónico"
                />
              </div>
            </div>

            <div className="flex justify-start mb-6 lg:mb-3 xl:mb-6">
              <label className="font-anek-tamil font-normal text-[#C0C0C0]">
                <label className="font-anek-tamil font-normal text-custom-purple">
                  *
                </label>{" "}
                Las instrucciones para recuperar la contraseña se enviarán al
                correo electrónico con el que te registraste como usuario.
              </label>
            </div>

            <div className="flex flex-col justify-center xl:mt-2 2xl:mt-16">
              {errorMsgServices && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errorMsgServices}
                </div>
              )}

              <button
                disabled={isSubmitting}
                type="submit"
                className="disabled:hover:bg-custom-purple-dark disabled:bg-custom-purple-dark disabled:cursor-not-allowed flex justify-center items-center   font-anek-tamil font-semibold  h-12 sm:h-16 lg:h-14 2xl:h-16 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-purple w-full hover:bg-custom-purple-dark focus:ring-4 focus:outline-none focus:ring-custom-purple-dark rounded-lg px-6 text-center"
              >
                {isSubmitting ? (
                  <div className="flex justify-center items-center">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 small-devices-820:w-5 small-devices-820:h-5 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Cargando...</span>
                  </div>
                ) : (
                  "Recuperar contraseña"
                )}
              </button>
            </div>
          </form>

          {/* -----------------------------   Finish form section  ----------------------------*/}
          {/* -----------------------------   Start Social networks section  ----------------------------*/}

          <div className="mt-6 small-devices-360:mt-8 md:mt-20 flex justify-center flex-col items-center">
            <label
              onClick={() => navigate(-1)}
              className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
            >
              Atrás
            </label>

            <Logo size={"small"} />
          </div>
        </div>
      </div>
      {/* -----------------------------   Finish Social networks section  ----------------------------*/}
      {/* -----------------------------   Finish login section  ----------------------------*/}
    </div>
  );
};

export default RecoveryAccountPage;
