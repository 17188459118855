import React from 'react'
import { useEffect } from 'react'
import { ReactComponent as People } from "../../Assets/Imgs/money-people.svg";

const WithdrawFunds = () => {
    //Scroll to top
useEffect(() => {
  window.scrollTo(0, 0)
}, [])

return (
<div className='bg-[#1D1D1D] p-4 min-h-screen lg:p-8'>
    <article className='w-full 2xl:max-w-screen-xl mx-auto'>
    <div>
         <h2 className='font-bold text-2xl md:text-3xl '>
         Retira fondos de tu balance con nuestros asesores por WhatsApp.</h2>

         <People className="md:my-6 md:mt-16 w-[100%]" />
        <p className='text-base lg:text-xl md:pt-10 text-white'> Puedes retirar stablecoins desde cualquier parte del mundo. Si tienes una cuenta bancaria de pesos mexicanos, soles o pesos colombianos puedes retirar a tu cuenta bancaria mediante una transferencia. Además, tu documento de identidad (cédula, DNI, otros) debe ser de Perú, Colombia o México.
        </p>
        <p className='text-base lg:text-xl pt-4 text-white'>El equipo de Imperium estará feliz de asesorarte durante el proceso de retiro de fondos, vía <a href='https://wa.me/message/PYXMG3SKHSLSP1'  target='blank' className='text-[#F97575] underline'>WhatsApp. </a> </p>
        <p className='text-base lg:text-xl pt-4 text-white'><span className='font-bold'>Recuerda:</span> Por el momento solo puedes retirar fondos que tengas en tu balance y que no hayan sido utilizados para comprar fracciones.</p>

    </div>
    <div className=' py-14 text-center'>
        <a href='https://wa.me/message/PYXMG3SKHSLSP1' target='_blank'  rel="noreferrer" className='py-[10px]  text-[12px] sm:text-sm px-2 lg:px-24 xl:px-32 lg:text-base  bg-[#CD91FF] text-white rounded-lg'>Empezar asesoría de retiro de fondos</a>
    </div>
    </article>
</div>
)
}

export default WithdrawFunds