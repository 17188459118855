import Logo from "components/Logo/Logo";
import Illuvium from "../../Assets/Imgs/Illuvium.jpg";

const WelcomeLoginImage = () => {
  return (
    <div className="hidden lg:block h-auto w-1/2 relative">
      <div className="z-10 h-3/5 w-3/5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="justify-start relative w-full h-full flex flex-col items-center">
          <Logo size={"big"} />

          <p className="text-white text-xl xl:text-2xl font-anek-tamil font-medium text-center mt-16">
            Diversifica tu portafolio comprando fracciones de los mejores NFTs,
            y administra mejor tu riesgo con Imperium.
          </p>
        </div>
      </div>

      <div className="h-full w-full absolute bg-gradient-to-b from-[#CD91FF] to-[#683E8B]"></div>
      <img
        src={Illuvium}
        className="pointer-events-none opacity-25 object-cover w-full h-full"
        alt="ollivium"
      />
    </div>
  );
};

export default WelcomeLoginImage;
