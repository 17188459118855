import twitter from '../../Assets/Imgs/twitter.svg'
import discord from '../../Assets/Imgs/discord.svg'
import instagram from '../../Assets/Imgs/instagram.svg'
import linkedin from '../../Assets/Imgs/linkedin.svg'
import ghost from '../../Assets/Imgs/ghost.svg'


const SocialMedia = () => {
  return (
          <div className='py-6 flex space-x-3  justify-left  '>
        <div>
          <a href="https://discord.com/invite/GYmJ8pZnCn" target='blank'>
            <img src={discord} alt="discord" className='opacity-[0.5] hover:opacity-[1]' />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/imperium_cool" target='blank'>
            <img src={twitter} alt="twitter" className='opacity-[0.5] hover:opacity-[1]' />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/imperium.cool/" target='blank'>
            <img src={instagram} alt="instagram" className='opacity-[0.5] hover:opacity-[1]' />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/imperiumlatam/" target='blank'>
            <img src={linkedin} alt="tiktok" className='opacity-[0.5] hover:opacity-[1]' />
          </a>
        </div>
        <div>
          <a href="https://imperium.ghost.io" target='blank'>
            <img src={ghost} alt=""  className='opacity-[0.5] hover:opacity-[1]' />
          </a>
        </div>
      </div>
  )
}

export default SocialMedia