
import React from 'react'
import moment from 'moment/moment'

import confirmed from '../../Assets/Imgs/Confirmed.png'
import pending from '../../Assets/Imgs/Pending.png'


const DepositLogsFiat = ({logsInfo}) => {
    const logsInfoBank = logsInfo?.filter((criptoData => criptoData.depositType === "BankTransfer"))
    console.log(logsInfo);


  return (
    <div>
        {logsInfoBank?.map((data,index) =>{
        
     
        const dateApi = data?.timestamp
        const date = moment.unix(dateApi / 1000).format('l');
        const confirmation = data?.status
        const amount = data?.amount
        const unit = data?.unit

     const UnitCurrency = (unit) => {
    const currencyMap = {
        FiatMXN: 'Pesos mexicanos',
        FiatPEN: 'Soles peruanos',
        FiatCOP: 'Pesos colombianos',
        FiatUSD: 'Dólares americanos'
    }

    return currencyMap[unit]
}

            return <article key={index} className=' h-[4rem] sm:text-h-[5rem] items-center justify-between sm:justify-around  mt-4 bg-[#0D0D0D] flex gap-2 lg:grid text-sm text-[#949494]  rounded-2xl grid-cols-4  place-items-center  px-2 lg:px-4'>
                <div className='flex flex-col gap-1 justify-center items-center'>
                    <div>
                        <p className='text-base text-white lg:hidden'>
                            Fecha
                        </p>
                    </div>
                    <div>
                    <p className='text-sm sm:text-base'>{date}</p>
                    </div>
                </div>
               <div className='flex flex-col gap-1 justify-center items-center'>
                <div>
                    <p className='text-base text-white lg:hidden'>Moneda</p>
                </div>
                    <div>
                       <p className=' text-[0.69rem] sm:text-base'>
                         {UnitCurrency(unit)}
                       </p>
                    </div>
               </div>
                <div className='flex flex-col  gap-1 justify-center items-center'>
                    <div>
                        <p className='text-base text-white lg:hidden'>Monto</p>
                    </div>
                   <div>
                    <p className='text-sm sm:text-base'> {amount === 0 ? 'No verificado' : `${amount}`}</p>
                   </div>
                </div>
                <div className='flex flex-col  gap-1 justify-center items-center'>
                    <div>
                        <p className='text-base text-white lg:hidden'>Procesado</p>
                    </div>
                    <div>
                        {confirmation === 'Confirmed' ? <img src={confirmed} className='w-5 lg:w-full' alt='confirmed'/> : <img src={pending} className='w-5 lg:w-full' alt='pending'/>}
                    </div>
                </div>
            </article>
        })}
    </div>
  )
}

export default DepositLogsFiat