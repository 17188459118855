import React from 'react'

import CardTemplate from '../../Assets/Imgs/card.png'
import { NavLink } from 'react-router-dom'

const NoFunds = () => {
  return (
   <div className='bg-[#1D1D1D] p-8 min-h-screen'>
    <article className='w-[95%] mt-5 pl-8'>
    <div className='flex gap-12 max-w-3xl mx-auto my-6'>
        <div>
        <h2 className='font-bold text-3xl '>
        No tienes saldo suficiente. Deposita fondos para comprar una fracción de [[MNLTH por RTFKT]]</h2>
        <p className='text-xl pt-5 text-[#8E8E8E]'>Puedes depositar fondos, mediante: transferencia bancaria, depósito de criptos y por tarjeta de crédito/Debito.
            Haz “click” en el botón de la opción que se acomode más a tu situación.
        </p>
        <p className='text-xl pt-5 text-[#8E8E8E]'>En caso tengas dudas acerca del proceso de depósito de fondos, consulta a nuestros asesores por 
            WhatsApp.
        </p>
    <div className='flex justify-between'>
        <div className='my-8'>
            <NavLink to='/dashboard/bank-transfer' className=' py-3 px-6 bg-custom-pink text-sm text-white rounded-3xl'>
                Transferencia Bancaria
        </NavLink>
        </div>
        <div className='my-8'>
            <NavLink to='/dashboard/deposit' className=' py-3 px-6 bg-custom-pink text-sm text-white rounded-3xl'>
                Depósito de Criptos
        </NavLink>
        </div>
        </div>
        
        
    </div>
        <div>
            <img src={CardTemplate} alt="" className='w-full min-w-[290px] min-h-full' />
        </div>
        

    </div>
   
    <div className='bg-[#949494] py-[.5px] ' />
       
          <div className=' mt-8 w-3/4'>
              <h2 className='font-bold text-3xl'>¿A dónde transferiré mis cripto?</h2>
            <p className='text-xl pt-4 text-[#808080] '>
               A tu cuenta en Imperium. ¡Esto nos permitirá procesar más rápido todas las compras de fracciones que hagas en nuestra App! 
            </p>
        </div>

    </article>
</div>
  )
}

export default NoFunds