import React, { useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ReactComponent as UserPlus } from "../../../Assets/Icons/UserPlus.svg";
import { ReactComponent as Mail } from "../../../Assets/Icons/Mail.svg";
import { ReactComponent as Check } from "../../../Assets/Icons/Check.svg";
import ModalTerms from "../../../components/ModalTerms/ModalTerms";
import "./RegisterToolTip.css";
import Logo from "../../../components/Logo/Logo";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";
import { useAuthContext } from "../../../contexts/authContext";

const baseUrl = process.env.REACT_APP_API_URL;

const schema = yup.object().shape({
  firstName: yup.string().trim().required("Ingresa tu nombre"),
  lastName: yup.string().trim().required("Ingresa tu apellido"),
  username: yup
    .string()
    .trim("Evita colocar espacios innecesarios")
    .strict(true)
    .required("Debes crear un nombre de usuario")
    .matches(
      /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?!.*[_.])$/,
      "El nombre de usuario no debe contener espacios ni caracteres especiales."
    )
    .test(
      "checkUserNameUnique",
      "Este usuario ya está en uso, elige otro",

      (username) =>
        fetch(`${baseUrl}/v1/user/?username=${username}`)
          .then((res) => res.json())
          .then((data) => {
            return !data.user;
          })
          .catch(() => {
            console.log("Server failed. Endpoint check if username exist");
            return false;
          })
    ),
  termsAndConditions: yup.boolean().required("empty").isTrue(),
  email: yup
    .string()
    .trim("Evita colocar espacios innecesarios")
    .strict(true)
    .email("Correo electrónico inválido")
    .required("Ingresa un correo electrónico")
    .test(
      "checkUserNameUnique",
      "Este correo electrónico ya se encuentra registrado.",
      (userEmail) =>
        fetch(`${baseUrl}/v1/user/account?userEmail=${userEmail.toLowerCase()}`)
          .then((res) => res.json())
          .then((data) => {
            return !data.user;
          })
          .catch(() => {
            console.log("Server failed. Endpoint check if email exist");
            return false;
          })
    ),
});

const RegisterPage = () => {
  // const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  // const [isHiddenPasswordConfirm, setIsHiddenPasswordConfirm] = useState(true);
  // const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsgServices, setErrorMsgServices] = useState(null);

  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let navigate = useNavigate();
  const { login } = useAuthContext();

  // !---------------------------MODAL --------------------------
  let [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const handleAcceptTerms = () => {
    setChecked(true);
    closeModal();
  };
  // !---------------------------
  const handleChange = () => {
    setChecked(!checked);
  };

  // // Handle toggle
  // const toggle = () => {
  //   setIsHiddenPassword(!isHiddenPassword);
  // };

  // // Handle toggleConfirm
  // const toggleConfirm = () => {
  //   setIsHiddenPasswordConfirm(!isHiddenPasswordConfirm);
  // };

  //   ---- URL SEARCH ----
  const [searchParams] = useSearchParams();

  const emailParams = searchParams.get("email");
  const nameParams = searchParams.get("name");
  const lastNameParams = searchParams.get("lastname");
  const userIdParams = searchParams.get("userId");
  const fromLoginParams = searchParams.get("fromLogin");

  // console.log("fromLoginParams, ", fromLoginParams);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: emailParams,
      firstName: nameParams,
      lastName: lastNameParams,
      userId: userIdParams,
    },
  });

  const onSubmit = async ({
    username,
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
  }) => {
    setErrorMsgServices(null);
    setIsSubmitting(true);
    console.log("SUBMIT, ", {
      username,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
    });

    const newUser = {
      timestamp: +new Date(),
      username: username,
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email.toLowerCase(),
      password: "",
      externalAccount: true,
      externalAccountName: "Google",
      externalID: userIdParams,
      isVerified: false,
    };

    console.log("nuevo usuario, ", newUser);

    const url = `${baseUrl}/v1/user/account?sourceName=Google`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(newUser),
      });

      const data = await response.json();

      // ==================

      const payload =
        process.env.REACT_APP_PAYLOAD === ""
          ? "http://localhost:3000"
          : process.env.REACT_APP_PAYLOAD;
      const urlAPI = process.env.REACT_APP_EMAIL_URL;
      const request = {
        destination: data.user.userEmail,
        emailCode: "confirmationEmail",
        payload: `${payload}/#/verified-account/${data.user.userEmail}/imperium`,
      };

      const responseVerificationAccount = await fetch(urlAPI, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
          key: process.env.REACT_APP_EMAIL_KEY,
        },
        body: JSON.stringify(request),
      });

      const dataVerificationAccount = await responseVerificationAccount.json();
      console.log(dataVerificationAccount);

      setTimeout(() => {
        if (data.ok) {
          login(
            data.user._id,
            data.token,
            data.user.userEmail,
            data.user.username
          );

          navigate(`/created-account/${data.user.userEmail}`, {
            replace: true,
          });
          setIsSubmitting(false);
        }
      }, 2000);
    } catch (error) {
      // todo: Throw error
      console.log("error, ", error);
      setTimeout(() => {
        setIsSubmitting(false);
        setErrorMsgServices("Ha ocurrido un error, intenta de nuevo");
      }, 1000);
      setTimeout(() => setErrorMsgServices(null), 4000);
    }
  };

  return (
    <div className="min-h-screen flex">
      <ModalTerms
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        handleAcceptTerms={handleAcceptTerms}
      />

      {/* -----------------------------   Start image section  ----------------------------*/}
      <WelcomeLoginImage />

      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}

      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-2/5">
          {fromLoginParams === "true" ? (
            <h1 className="lg:block text-3xl md:text-4xl font-extrabold mb-6 mt-5 font-anek-tamil">
              Aún no tienes cuenta, registrate aquí
            </h1>
          ) : (
            <h1 className="lg:block text-3xl md:text-4xl font-extrabold mb-6 mt-5 font-anek-tamil">
              Estás a un paso de unirte a Imperium
            </h1>
          )}

          {/* -----------------------------   Start form section  ----------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col lg:flex-row w-full gap-x-4 lg:mb-4 mb-auto">
              {/* --------FirstName---------- */}
              <div
                className={`w-full ${
                  errors.lastName ? "mb-0" : "mb-2"
                } lg:w-1/2 lg:mb-auto`}
              >
                {errors.firstName && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.firstName?.message}
                  </div>
                )}

                {!errors.firstName && errors.lastName && (
                  <div className="hidden lg:block my-2 text-custom-red font-anek-tamil">
                    <br />
                  </div>
                )}

                <div className="relative w-full">
                  <input
                    {...register("firstName")}
                    type="text"
                    id="firstName"
                    className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-8 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-custom-purple focus:ring-custom-purple focus:border-custom-purple`}
                    placeholder="Nombre"
                    required=""
                    disabled={true}
                  />
                  <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  </div>
                </div>
              </div>

              {/* --------LastName---------- */}

              <div
                className={`w-full ${
                  errors.username ? "mb-0" : "mb-2"
                } lg:w-1/2 lg:mb-auto`}
              >
                {errors.lastName && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.lastName?.message}
                  </div>
                )}

                {!errors.lastName && errors.firstName && (
                  <div className="hidden lg:block my-2 text-custom-red font-anek-tamil">
                    <br />
                  </div>
                )}
                <div className="relative w-full">
                  <input
                    {...register("lastName")}
                    type="text"
                    id="lastName"
                    className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-8 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-custom-purple focus:ring-custom-purple focus:border-custom-purple`}
                    placeholder="Apellido"
                    required=""
                    disabled={true}
                  />
                  <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  </div>
                </div>
              </div>
            </div>

            {/* --------Username---------- */}

            <div className="mb-2 lg:mb-4">
              {errors.username && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.username?.message}
                </div>
              )}
              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <UserPlus className="text-red-500  w-7 h-7" />
                </div>

                <input
                  {...register("username")}
                  type="text"
                  id="username"
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input ${
                    !errors.username && touchedFields.username
                      ? "text-custom-purple"
                      : "text-white"
                  } focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Crea el nombre de tu usuario"
                  required=""
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  {errors.username?.message !== "empty" &&
                    errors.hasOwnProperty("username") &&
                    touchedFields.username}
                  {errors.username?.message === "empty" &&
                    touchedFields.username}
                  {!errors.hasOwnProperty("username") &&
                    touchedFields.username && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )}
                </div>
              </div>
            </div>

            {/* --------Email---------- */}

            <div className="mb-2 lg:mb-4">
              {errors.email?.message !== "empty" &&
                errors.hasOwnProperty("email") && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.email?.message}
                  </div>
                )}
              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Mail className="text-red-500  w-7 h-7" />
                </div>

                <input
                  {...register("email")}
                  type="text"
                  id="email"
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-custom-purple focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Correo electrónico"
                  required=""
                  disabled={true}
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  <Check className="text-custom-gray-light--input w-6 h-6" />
                </div>
              </div>
            </div>

            {/* --------Terms and conditions---------- */}

            <div className="flex justify-start mb-6 lg:mb-3 xl:mb-6">
              <div className="flex items-center">
                <input
                  {...register("termsAndConditions")}
                  checked={checked}
                  onChange={handleChange}
                  id="checked-checkbox"
                  type="checkbox"
                  className="mr-3 w-4 h-4 text-custom-purple bg-gray-100 rounded border-gray-300 focus:ring-custom-purple dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <label
                  htmlFor="terms"
                  className="font-anek-tamil font-normal text-custom-gray-light--terms"
                >
                  He leido y acepto los{" "}
                  <label
                    onClick={openModal}
                    className="font-anek-tamil font-normal text-custom-purple hover:underline"
                  >
                    términos y condiciones
                  </label>
                </label>
              </div>
            </div>

            {/* --------Submit button---------- */}
            <div className="flex flex-col justify-center xl:mt-2 2xl:mt-16">
              {errorMsgServices && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {/* {'Ha ocurrido un error, intenta de nuevo'} */}
                  {errorMsgServices}
                </div>
              )}
              <button
                disabled={!checked || isSubmitting}
                type="submit"
                className="flex justify-center items-center disabled:hover:bg-custom-purple  disabled:cursor-not-allowed disabled:opacity-75 font-anek-tamil font-semibold  h-12 sm:h-16 lg:h-14 2xl:h-16 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-purple w-full hover:bg-custom-purple-dark focus:ring-4 focus:outline-none focus:ring-custom-purple-dark rounded-lg px-6 text-center"
              >
                {isSubmitting && isValid ? (
                  <div className="flex justify-center items-center">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 small-devices-820:w-5 small-devices-820:h-5 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Cargando...</span>
                  </div>
                ) : (
                  "Crear nueva cuenta"
                )}
              </button>
            </div>
          </form>

          {/* -----------------------------   Finish form section  ----------------------------*/}
          {/* -----------------------------   Start Social networks section  ----------------------------*/}

          <div className="mt-6 small-devices-390:mt-10 mb-5 md:mt-20 flex justify-center flex-col items-center">
            <label
              htmlFor="terms"
              className="font-anek-tamil font-normal text-lg text-base text-custom-gray-light--terms"
            >
              ¿Ya tienes una cuenta?{" "}
              <Link
                to={"/auth/login"}
                className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
              >
                Ingresa aquí
              </Link>
            </label>
            <Logo size={"small"} />
          </div>
        </div>
      </div>
      {/* -----------------------------   Finish Social networks section  ----------------------------*/}
      {/* -----------------------------   Finish login section  ----------------------------*/}
    </div>
  );
};

export default RegisterPage;
