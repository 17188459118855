import React from "react";

import ReactDOM from "react-dom/client";
import App from "./App";
import "./tailwind/index.css";
import "./sass/App.scss";
import "animate.css";
import { AuthContextProvider } from "./contexts/authContext";

if (
  process.env.REACT_APP_ENVIRONMENT === "production" ||
  process.env.REACT_APP_ENVIRONMENT === "qa"
) {
  console.log = () => {};
}

console.log("environment,", process.env.REACT_APP_ENVIRONMENT);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);
