import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../layout/MainLayout/MainLayout";
import NotFound404 from "../pages/NotFound404/NotFound404";
import ProjectPage from "../pages/ProjectPage/ProjectPage";
import ProjectsPage from "../pages/ProjectsPage/ProjectsPage";

export const ProjectsRouter = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route index element={<ProjectsPage />} />
        <Route path="project/:id" element={<ProjectPage />} />
      </Route>
        <Route path={"*"} element={<NotFound404 />} />
    </Routes>
  );
};
