import LogoImperium from "../../Assets/Imgs/Logo.png";

const Logo = ({ size }) => {
  return size === "big" ? (
    <img
      src={LogoImperium}
      alt="imperiumLogo"
      className="rotate-[8deg] h-10 lg:h-20 mb-6"
    />
  ) : (
    <img
      src={LogoImperium}
      alt="imperiumLogo"
      className={`rotate-[8deg] mt-5 small-devices-390:mt-20 small-devices-540:mt-10 md:mt-20 lg:hidden h-8 left-1/2`}
    />
  );
};

export default Logo;
