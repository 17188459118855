import React from 'react'
import "./EvolutionTimeline.scss"

const EvolutionTimeline = ({items}) => {

    // console.log('items', items)

  return (
    <>
    {
        items && (
            <div className="evolution-item-container grid grid-flow-row md:grid-cols-5">
                   {
                    items.map((e, i) => (
                        <div className="flex flex-col pt-8 md:pt-5 items-center evolution-item mb-10" key={i}>

                        <div className='rounded-md w-full h-[20rem] md:w-[5rem] md:h-[5rem] overflow-hidden'>
                            <img src={e.photoItem} alt={`evolutionItems_${i}`} className="w-full h-full object-cover" />
                        </div>    
                        {/* <div className="md:pt-5 w-full md:w-auto flex-col"> */}
                        <div className="md:pt-5 w-full md:w-auto flex-col h-32">
                            <h3 className="capitalize font-semibold py-2 text-lg">{e.title}</h3>
                            {
                                e.extraInformation.length !== 0 && (
                                    <p className="font-medium  text-[14px] leading-[14px] py-[.5rem]">
                                        {e.extraInformation}
                                    </p>
                                )
                            }

                            {/* {
                                e.initialPrice && (
                                    <p className="font-medium  text-[14px] leading-[14px] pb-[.5rem]">
                                    Precio inicial:
                                    <span className="font-medium text-[16px]"> $ {e.initialPrice} </span>
                                </p>
                                )
                            } */}
                            {
                                e.currentPrice && (
                                    <p className="font-medium  text-[14px] leading-[14px] pb-[.5rem]">
                                    Precio:
                                    <span className="font-medium text-[16px]"> $ {e.currentPrice} </span>
                                </p>
                                )
                            }
                        </div>
                    </div>
                    ))
                }
    </div>
        )
    }
    </>
    
  )
}

export default EvolutionTimeline