import {depositContextData} from '../../contexts/depositContext'
import { useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import leftArrow from '../../Assets/Imgs/leftArrow.svg'
import { useAuthContext } from "contexts/authContext";
import Loader from "../../components/Loader/LoaderButtonDeposits";
import Cookies from "universal-cookie"
import SocialMedia from '../../components/Deposits/SocialMedia'

const MY_AUTH_USER_ID = 'idUserImperium'
const MY_AUTH_TOKEN = "tokenImperium";

const StepTwotoDeposit = () => {
const cookies = new Cookies();
const {email} = useAuthContext();
const [user] = useState(cookies.get(MY_AUTH_USER_ID))
const [token] = useState(cookies.get(MY_AUTH_TOKEN))
const {criptoValue} = useContext(depositContextData)
const {blockchainValue} = useContext(depositContextData)
const {unitValue} = useContext(depositContextData)

// const [unitValue,setUnitValue] = useState(depositContextData);
// const [blockchain,setBlockChain] = useContext(depositContextData)

const [errorMsg,setErrorMsg] = useState(false)
const [isSubmitting, setIsSubmitting] = useState(false);

const navigate = useNavigate()

console.log(user);

const [input, setInput] = useState('')



const baseUrl = process.env.REACT_APP_API_URL;
const url = `${baseUrl}/v1/deposits`


const request = {
  userID: user,
  unit: unitValue,
  blockchain: blockchainValue,
  TXID: input,
}

console.log(request)

const handleSubmit = async e =>{
e.preventDefault()

if(user === 'undefined'){
setErrorMsg(true)
return
} else {

// Registrar Depositos
fetch(url, {
  method: 'POST',
  body: JSON.stringify(request),
  headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
}).then( resp =>{


setIsSubmitting(true)
if(resp.status === 200){

console.log(resp)
const registerUrl = process.env.REACT_APP_EMAIL_URL

const request ={
destination: email,
emailCode: 'registerDeposit',
payload: criptoValue

}
console.log(request)

// Peticion Email al registrar depositos
fetch(registerUrl, {
      method: 'POST', 
      body: JSON.stringify(request),
      headers:{ Accept: "application/json",
     "Content-Type": "application/json;charset=utf-8",
      key: process.env.REACT_APP_EMAIL_KEY,
  }
}).then( resp =>{
if(resp.status === 200){

navigate('/dashboard/deposit-step3')
}
}).catch(error =>{
console.log(error)
})
}


}).catch( error =>{
console.log(error)
})
}


}

return (
<div className='bg-[#1D1D1D] p-4 lg:p-8 min-h-screen'>
  <article className='w-full mt-5 mx-auto 2xl:max-w-screen-xl'>
    <div className='flex justify-between pb-12'>
      <div className='w-full text-center lg:text-left'>
        <h2 className='font-bold text-2xl md:text-3xl '>
          ¡Ya casi terminamos!</h2>
        <p className='text-base md:text-xl pt-4 text-[#808080] '>
          Para procesar la transferencia de tus criptos necesitamos que nos compartas el "Transaction ID". Pégalo aquí:
        </p>
        <div className='flex justify-left  '>

          <form onSubmit={handleSubmit} className='w-full'>
            <input placeholder='Transaction ID' value={input} onChange={(e)=> setInput(e.target.value)}
            required className='mx-auto placeholder-opacity-5 pl-10 py-4 my-6 bg-transparent relative border-[#CD91FF]
            text-white text-base md:text-lg  border-2 flex justify-left lg:w-2/4 rounded-2xl placeholder:text-white'/>
            {errorMsg ? <p className='mt-4 text-custom-pink px-4 text-lg'>Lo siento, hubo un problema al registrar tu
              depósito. ❌</p> : null}
              
            <div className='mt-8 flex justify-center items-center gap-4 lg:gap-8 text-white '>
              <button onClick={()=>{
                navigate('/dashboard/deposit-step1')
                }} className='border-[#CD91FF] px-12 lg:px-20 relative py-2 rounded-3xl text-white text-base md:text-lg border-2'>Atrás
                <img src={leftArrow} alt="copy clipboard" className='absolute top-3 left-6' />
              </button>
              <Loader isSubmitting={isSubmitting} isDisabled={isSubmitting} text={"¡Listo!"} />
            </div>
          </form>
        </div>
    </div>
  </div>


    <div className='bg-[#949494] py-[.5px]' />
    <div className=' mt-8 text-center lg:text-left '>
      <h2 className='font-bold text-2xl md:text-3xl'>¿A dónde transferiré mis cripto?</h2>
      <p className='text-base md:text-xl pt-4 text-[#808080] '>
        A tu cuenta en Imperium. ¡Esto nos permitirá procesar más rápido todas las compras de fracciones que hagas en
        nuestra App!
      </p>
    </div>
    <div className='mt-8'>
      <h2 className='font-bold text-center lg:text-left text-2xl md:text-3xl'>Síguenos en nuestras redes sociales</h2>
              <SocialMedia/>
    </div>
  </article>
</div>
)

}

export default StepTwotoDeposit