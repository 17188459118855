export const faq = [
  {
    id: 1,
    title: "¿Qué productos ofrecemos?",
    info: `- En primer lugar el "Opportunity explorer". Te ofrecemos la posibilidad de invertir en los mejores activos digitales con tan sólo $10.
      
      - En segundo lugar el "Imperium Collection 1". Te ofrecemos la posibilidad de invertir en un fondo de los mejores activos digitales. El equivalente al S&P del Metaverso.
      `,
  },
  {
    id: 2,
    title: "¿Qué son fracciones?",
    info: "Las fracciones son unidades contables que representan propiedad sobre un activo. Para ejemplificar, si ofrecemos 100 fracciones de un terreno de Sandbox, y tu adquieres 10, significa que tendrás 10% de la propiedad del activo (10 fracciones tuyas ÷ 100 fracciones en total). Esto también quiere decir que recibirás 10% de cualquier ingreso que surja del activo, como por ejemplo, el alquiler o la venta del terreno.",
  },
  {
    id: 3,
    title: "¿Cuántas fracciones puedo comprar? ¿Existe una cantidad mínima?",
    info: "No existen máximos de compra de una fracciones. La cantidad mínima de fracciones a comprar por activo es de 1 fracción equivalente a $10.",
  },
  {
    id: 4,
    title: "¿Cómo puedo depositar para comprar? ¿Qué métodos de pago aceptan?",
    info: `Para depositar haz click en tu foto de perfil -> "Depositar fondos" -> Selecciona "Transferencia bancaria" o "Depósito en criptos" -> Selecciona tu método de pago preferido -> Sigue las indicaciones para confirmar el depósito.

    Por el momento como método de pago aceptamos transferencias bancarias y depósitos en cripto. Pronto aceptaremos pagos con tarjetas de débito / crédito.`,
  },
  {
    id: 5,
    title: "¿Cobran fees por sus servicios?",
    info: `Sí. Para los productos fraccionalizados, Imperium cobra un fee de 5% tomando una cuota de abastecimiento en cada oferta inicial. Esta cifra se incluye en el precio de la oferta que está reflejado en la plataforma. Por otro lado, para el Imperium Collection 1, Imperium cobra una subscripción anual - si la inversión es menor a $5k, el fee equivale a 2% (anual), por otro lado si la inversión es igual o mayor a $5k, se cobra un flat fee de $99 (anual).`,
  },
  {
    id: 6,
    title: '¿Cuáles son los beneficios de ser un "Imperium Holder"?',
    info: `- Acceso a nuestras comunidades de Discord, WhatsApp y Telegram. Atención personalizada del equipo 24/7.
    - Pregunta lo que quieras, cuando quieras. Información "alpha" de futuras oportunidades.
    - Invitaciones exclusivas a nuestros eventos presenciales y virtuales.`,
  },
];

export default faq;
