import { useState } from 'react'
import {useForm} from 'react-hook-form'
import LoaderButtonEmail from 'components/Loader/LoaderButtonEmail';
import {countries} from '../../config/countries.js'

const BankTransferForm = () => {

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSubmited] = useState(false)

    const {register, formState:{errors}, handleSubmit} = useForm()
    
    const onSubmit = async (data) => {
      
      setIsSubmitting(true)
      
      const emailUrl = `https://www.wolframcloud.com/obj/christianp/Imperium/Prototypes/MVP01/APIs/BankTransferOtherCurrencies?Email=${data.email}&Country=${data.countries}`

      try {
        
        const response = await fetch(emailUrl);

        const data = await response.json()
console.log(data)

      } catch (error) {
        console.log('error,', error.message)
      }

      setTimeout(() => setIsSubmitting(false), 2000)
    }


    return (
            <form onSubmit={handleSubmit(onSubmit)} className='mt-8'>
            <div className='flex flex-col gap-4 justify-center items-center md:flex-row'>

            <div  >
            <select  {...register('countries')}  disabled={isSubmited ? true : false} className='  py-4  w-[260px] bg-transparent border-[#CD91FF] text-[#808080] border-2 flex justify-left rounded-2xl'>
            {countries.map((countries,index) =>{
                return <option className='max-w-[320px]' key={index}>{countries}</option>
            })}
            </select>
            </div>

            <div className='relative  max-w-[350px]'>
            {errors.email?.type === 'required' && <p className='text-red-500 text-sm md:text-base  whitespace-nowrap left-2 absolute top-16 '>Ingrese un correo electrónico</p>}
            {errors.email?.type === 'pattern' && <p className='text-red-500 text-sm md:text-base whitespace-nowrap left-2 absolute top-16 
            '>Correo electronico invalido</p>}

            <input  disabled={isSubmited ? true : false}  type='text'
            {...register('email',
            {required:true},
           )}
           {...register('email',
            {pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i })}
            placeholder='Correo electrónico' className='  py-4 border-[#CD91FF] bg-[#1D1D1D] w-[260px] placeholder-gray-400 text-white border-2 flex justify-left rounded-2xl focus-within:bg-transparent' />
            </div>
            
        </div>
        <div className='flex justify-center'>
            {/* <button onClick={onSubmit}  className={`py-2 mt-8 px-48 bg-[#CD91FF] text-white rounded-2xl ${dataStored ? 'hidden' : 'static'}` }>Enviar datos</button> */}
			{!isSubmited ? <LoaderButtonEmail isSubmitting={isSubmitting} isDisabled={isSubmitting} text={isSubmited ? "Email Enviado" : "Enviar datos"}  /> : null}
        </div>
        {isSubmited  && <div> <p className='font-bold animate__animated animate__fadeIn animate__faster text-lg md:text-xl lg:text-2xl text-center text-white my-8'>¡Se han cargado tus datos correctamente!</p> </div> }
        </form>
  )
}

export default BankTransferForm