import { useState } from 'react'
import {HiPlus, HiOutlineMinus} from 'react-icons/hi'

const SingleQuestion = ({title,info,id}) => {
const [showInfo, setShowInfo] = useState(false)

return (
<div key={id} className='bg-[#26263828] w-full transition-all flex flex-col justify-center rounded-3xl shadow-2xl'>
  <div onClick={()=> setShowInfo(!showInfo)}  className='flex justify-left py-4 px-4 text-lg md:text-xl'>
    <button className='px-4'>
      {showInfo ?
      <HiOutlineMinus /> :
      <HiPlus />}
    </button>
    {title}
  </div>
  {showInfo && <div className='py-2 px-6 pb-4 text-justify whitespace-pre-line text-base md:text-lg'>
    <hr className='opacity-[0.3] py-2 '/>
    {info} 
    </div>}
</div>
)
}

export default SingleQuestion