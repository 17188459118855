import NavbarMenu from "./NavbarMenu";
import { GoThreeBars } from "react-icons/go";
import Logo from "../../Assets/Imgs/Logo.png";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import DashboardMenu from "components/Dashboard/DashboardMenu";
import { useAuthContext } from "../../contexts/authContext";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const { isAuthenticated: isLoggedIn } = useAuthContext();

  const handleMenu = () => {
    setMenu(!menu);
  };

  return (
    <nav className="w-full sticky top-0 lg:fixed z-50 py-4 px-4 bg-[#0D0D0D]  ">
      <div className="flex justify-between lg:justify-around items-center">
        <div>
          <NavLink to="/">
            <img src={Logo} alt="imperiumLogo" className="h-10 xl:h-12" />
          </NavLink>
        </div>
        <div className="hidden lg:block">
          <NavbarMenu />
        </div>
        <button className="px-4 lg:hidden" onClick={handleMenu}>
          <GoThreeBars className="text-red-400 text-3xl" />
        </button>
      </div>

      {/* menu-link | show-menu from input.css */}

      <div
        className={`${!isLoggedIn && menu && "show-menu-unlogged"} ${
          menu ? `menu-links  show-menu ` : "menu-links "
        }`}
      >
        <DashboardMenu handleMenu={handleMenu} />
      </div>
    </nav>
  );
};

export default Navbar;
