import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";
import { ReactComponent as PwdSuccess } from "../../../Assets/Icons/PwdSuccess.svg";
import LoadingPage from "../../LoadingPage/LoadingPage";
import Logo from "../../../components/Logo/Logo";
import { isExistingUserByEmail } from "../../../helpers/checkingExistingUserByEmail";

const SuccessPwdResetPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  let { email } = useParams();

  useEffect(() => {
    const handleCheckingUser = async () => {
      const isExistingUser = await isExistingUserByEmail(email);

      if (!isExistingUser.dataUser) {
        navigate("/does-not-exist", { replace: true });
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };

    handleCheckingUser();
  }, [email, navigate]);

  return (
    <div className="min-h-screen flex">
      <LoadingPage isLoading={isLoading} />
      {/* -----------------------------   Start image section  ----------------------------*/}
      <WelcomeLoginImage />
      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}
      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-[45%]">
          <h1 className="md:w-3/4 lg:w-full text-3xl md:text-4xl font-extrabold mb-8 font-anek-tamil">
            Tu contraseña ha sido actualizada exitosamente
          </h1>

          <p className="font-poppins font-light text-custom-gray-light mb-8 small-devices-820:mb-5 lg:mb-5 xl:mb-8 ">
            Ahora ingresa con tu nueva contraseña.
          </p>

          <div className="flex justify-center small-devices-820:mt-12 small-devices-360:mt-8 mb-6 small-devices-390:mb-7 xl:mb-8 2xl:mb-16">
            <PwdSuccess className="w-32" />
          </div>
          <Link to="/auth/login">
            <button
              type="button"
              className="font-anek-tamil font-semibold  h-12 sm:h-16 lg:h-14 xl:h-16 small-devices-360:text-lg text-xl shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-purple w-full hover:bg-custom-purple-dark focus:ring-4 focus:outline-none focus:ring-custom-purple-dark rounded-lg px-6 text-center"
            >
              Ir a inicio
            </button>
          </Link>
          {/* -----------------------------   Finish form section  ----------------------------*/}
          {/* -----------------------------   Start Social networks section  ----------------------------*/}
          <div className="mt-6 small-devices-360:mt-8 md:mt-20 flex justify-center flex-col items-center">
            <Logo size={"small"} />
          </div>
        </div>
      </div>
      {/* -----------------------------   Finish Social networks section  ----------------------------*/}
      {/* -----------------------------   Finish login section  ----------------------------*/}
    </div>
  );
};

export default SuccessPwdResetPage;
