import {depositContextData} from '../../contexts/depositContext'
import { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import SocialMedia from '../../components/Deposits/SocialMedia'
import { useState, useEffect } from 'react'




const Deposit = () => {

const {
// setDepositValue,

setAddressValue,

setCriptoValue,
unitValue,
setUnitValue,
blockchainValue,
setBlockChainValue,

} = useContext(depositContextData)


const [toggleModal, setToggleModal] = useState(false)
const [selectCripto, setSelectCripto] = useState('Selecciona')



const navigate = useNavigate()


//Scroll to top
useEffect(() => {
window.scrollTo(0, 0)
}, [])


  const HandleSubmit = () =>{
  switch (selectCripto) {
  case 'Bitcoin':
  case 'Ethereum':
  case 'Cardano':
  navigate('/dashboard/deposit-step1')
  break;
  default:
  break;
  }
}
const handleModal = () => {
setToggleModal(!toggleModal)
}

const cryptoValues = {
Bitcoin: {
address: '18gmPJLjqbCwgj1Tz2XB4uG4ottNPcyAAA',
cripto: 'bitcoin',
unit: 'BTC',
blockchain: '[Bitcoin,Mainnet]'
},
Ethereum: {
address: '0x0b3593a3f61409f6c0d2036a7780e9d03b452bef',
cripto: 'ether',
unit: 'ETH',
blockchain: '[Ethereum,Mainnet]'
},
Cardano: {
address: 'addr1vyfp04wmqecdnl8llpvzj222ar0a9lvssze0ysac8suc2wghl6qu7',
cripto: 'ada',
unit: 'ADA',
blockchain: '[Cardano,Mainnet]'
}
}

const setCryptoState = (crypto) => {
setToggleModal(false)
setSelectCripto(crypto)
const { address, cripto, unit, blockchain } = cryptoValues[crypto]
setAddressValue(address)
setCriptoValue(cripto)
setUnitValue(unit)
setBlockChainValue(blockchain)
}

const handleBTC = () => setCryptoState('Bitcoin')
const handleEthereum = () => setCryptoState('Ethereum')
const handleCardano = () => setCryptoState('Cardano')


console.log(unitValue)
console.log(blockchainValue);




return (
<div className='bg-[#1D1D1D] p-4 md:p-8  min-h-screen'>
  <article className='w-full mt-4 mx-auto 2xl:max-w-screen-xl'>
    <div>
      <h2 className='font-bold text-center md:text-left text-2xl md:text-3xl '>
        Deposita tu cripto favorita</h2>
      <form className='p-4 mt-4 flex flex-col items-left justify-left gap-6'>
        <div className='flex gap-8  '>
          <div className=' flex items-center'>
            <p className='text-[#808080] text-base md:text-lg'>Selecciona una cripto</p>

          </div>
          <button type="button" onClick={handleModal}
            className="py-4 px-8 md:w-1/4 text-center border-[#CD91FF] text-[#808080] border-2 flex justify-left rounded-2xl">
            {selectCripto}
          </button>

          <div tabIndex="-1" className={`overflow-y-auto overflow-x-hidden fixed animate__animated animate__fadeIn
            animate__faster top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-[rgba(40,40,40,0.5)]
            justify-center items-center ${!toggleModal ? 'hidden' : null}`}>
            <div className="relative p-4 mx-auto mt-40 w-full max-w-md h-full md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" onClick={handleModal}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="py-4 px-6 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                    Escoge tu cripto
                  </h3>
                </div>

                <div className="p-6">
                  <ul className="my-4 space-y-3">
                    <li>
                      <button type='button' onClick={handleBTC}
                        className="flex items-center text-left p-3 text-base font-semibold text-gray-900 bg-gray-50 rounded-lg w-full hover:bg-[#CD91FF] group hover:shadow hover:text-white transition-all">
                        <span className="flex-1 ml-3 whitespace-nowrap">Bitcoin</span>
                        <span
                          className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">BTC</span>
                      </button>
                    </li>
                    <li>
                      <button type='button' onClick={handleEthereum}
                        className="flex items-center text-left p-3 text-base font-semibold text-gray-900 bg-gray-50 rounded-lg w-full hover:bg-[#CD91FF] group hover:shadow hover:text-white transition-all">
                        <span className="flex-1 ml-3 whitespace-nowrap">Ethereum</span>
                        <span
                          className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">ETH</span>
                      </button>
                    </li>
                    <li>
                      <button type='button' onClick={handleCardano} className={`flex items-center text-left p-3
                        text-base font-semibold text-gray-900 bg-gray-50 rounded-lg w-full hover:bg-[#CD91FF] group
                        hover:shadow hover:text-white transition-all`}>

                        <span className="flex-1 ml-3 whitespace-nowrap">Cardano</span>
                        <span
                          className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">ADA</span>
                      </button>
                    </li>
                    <li>
                      <NavLink to='/dashboard/other-criptos'
                        className="flex items-center text-left p-3 text-base font-semibold text-gray-900 bg-gray-50 rounded-lg w-full hover:bg-[#CD91FF] group hover:shadow hover:text-white transition-all">
                        <span className="flex-1 ml-3 whitespace-nowrap">Otros</span>
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type='button' onClick={HandleSubmit}
          className='py-2 font-semibold px-10 mt-4 w-40  bg-[#CD91FF] text-white rounded-xl'>Continuar</button>
      </form>

    </div>
    <div className='bg-[#949494] py-[.5px] mt-8' />
    <div className=' mt-8  mx-auto'>
      <h2 className='font-bold text-center md:text-left text-2xl md:text-3xl '>¿A dónde transferiré mis cripto?</h2>
      <p className='text-base md:text-xl pt-4 text-[#808080] '>
        A tu cuenta en Imperium. ¡Esto nos permitirá procesar más rápido todas las compras de fracciones que hagas en
        nuestra App!
      </p>
    </div>
    <div className='mt-8'>
      <h2 className='font-bold text-center md:text-left text-2xl md:text-3xl'>Síguenos en nuestras redes sociales</h2>
      <SocialMedia />
    </div>

  </article>

</div>
)
}

export default Deposit