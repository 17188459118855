import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout/AuthLayout";
import LoginPage from "../pages/authPages/LoginPage/LoginPage";
import RecoveryAccountPage from "../pages/authPages/RecoveryAccountPage/RecoveryAccountPage";
import RegisterPage from "../pages/authPages/RegisterPage/RegisterPage";
import RegisterLoginExternal from "../pages/authPages/RegisterPage/RegisterLoginExternal";
import SuccessPwdResetPage from "../pages/authPages/SuccessPwdResetPage.js/SuccessPwdResetPage";
import NotFound404 from "../pages/NotFound404/NotFound404";

export const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="register-external" element={<RegisterLoginExternal />} />
        <Route path="forgot-password" element={<RecoveryAccountPage />} />
        <Route path="successful-password-reset/:email" element={<SuccessPwdResetPage />} />
      </Route>
      <Route path="*" element={<NotFound404 />} />
      </Routes>
  );
};
