import { NavLink} from 'react-router-dom';

//  Icons
import { ReactComponent as Loss } from "../../Assets/Icons/Loss.svg";
import { ReactComponent as Profit } from "../../Assets/Icons/Profit.svg";

const UserLogs = ({fractionLogs}) => {

return (
<>
    {
    fractionLogs?.map((item, i) => {

    return (<article
        className='p-4 max-w-7xl mx-auto mt-4 bg-[#0D0D0D] text-sm sm:text-base shadow-lg flex flex-col justify-around sm:justify-between  rounded-lg sm:flex-row lg:py-2 lg:px-4  '
        key={i}>

        <div className='flex  justify-between  items-center gap-4 lg:gap-8'>
            <NavLink to={`/project/${item.projectID}`}> <img src={item.image}
                className='w-[4rem] h-[3rem] bg-[#414040] object-cover sm:w-[6rem] sm:h-[4rem] ' alt={item.name} />
            </NavLink>
           <div className=' max-w-[10.5rem] md:max-w-[12.5rem]'>
             <NavLink to={`/project/${item.projectID}`}> <h3 className='text-custom-gray-light text-base '>
                {item.name}</h3>
            </NavLink>
           </div>
        </div>

        <div className='grid grid-cols-2  xl:grid-cols-4  md:flex-row items-center justify-between    lg:text-base'>
            <div className='flex flex-col items-center justify-between mt-4 lg:mt-2 '>
                <div>
                    <p className='whitespace-nowrap'>Cantidad de fracciones</p>
                </div>
                <div>
                    <p className='text-[#949494]'>{item.totalFractions}</p>
                </div>
            </div>
            <div className='flex flex-col   items-center justify-between mt-4 lg:mt-2'>
                <div>
                    <p>Total Invertido</p>
                </div>
                <div>
                <p className='text-[#949494]'>{`$ ${(item.investmentAmount)?.toFixed(2) || '0.00'}`}</p>
                </div>
            </div>
            <div className='flex  flex-col items-center justify-between mt-4 lg:mt-2'>
                <div>
                    <p>Valor actual</p>
                </div>
                <div>
                    <p className='text-[#949494]'>{`$ ${(item.currentAmount)?.toFixed(2) || '0.00'}`}</p>
                </div>
            </div>
            <div className='flex flex-col items-center  justify-between mt-4 lg:mt-2'>
                <div className='flex items-center gap-4'>
                    <p>Retorno</p>
                </div>
                    
{/* ================== */}

                <div className='flex items-center gap-1 whitespace-nowrap'>
                    {
                        (item.returnAmount && item.returnAmount >= 0) && <Profit className='w-3'/>
                    }
                    {
                        (item.returnAmount && item.returnAmount < 0) && <Loss className='w-3' />
                    }

                    {
                        item.returnAmount ?
                        (
                         <p className={item.returnAmount >= 0 ? `text-[#68E365]` : `text-[#F1494E]` }>{`$ ${item.returnAmount?.toFixed(2)} (${item.returnPercent?.toFixed(2)}%)`}</p>
                        ) :
                        (
                         <p className='text-[#949494]'>$ 0.00</p>
                        )
                    }

                </div>
            </div>
        </div>
    </article>)})
    }
</>
)
}

export default UserLogs