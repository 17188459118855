import { Ripples } from "@uiball/loaders";

const LoadingPage = ({ isLoading }) => {
  return (
    <div
      className={` ${isLoading ? "visible" : "invisible"} ${
        isLoading ? "opacity-100" : "opacity-0"
      }  transition-all ease-in-out duration-300 bg-custom-black h-screen w-screen flex justify-center items-center fixed z-20`}
    >
      <Ripples size={120} speed={2} color="#CD91FF" />
    </div>
  );
};

export default LoadingPage;
