import React from 'react'
import homeOffice from '../../Assets/Imgs/homeOffice.svg'
import { useNavigate } from 'react-router-dom'
import leftArrow from '../../Assets/Imgs/leftArrow.svg'
const OthersCriptos = () => {

const navigate = useNavigate()

return (
<div className='bg-[#1D1D1D] p-4 lg:p-8 min-h-screen'>
    <article className='w-full mt-5 mx-auto max-w-2xl'>        
        <div>
            <h2 className='font-bold text-3xl '>
                Deposita tus cripto preferidas con nuestra asesoría </h2>
            <p className='text-xl pt-8 text-[#808080] '>
                 Si tienes otras criptos que aún no están en la lista, escríbenos por WhatsApp y te
                ayudaremos paso a paso en el depósito de criptos que quieras.
            </p>
            <div className='mt-10 mx-auto flex flex-col-reverse md:flex-row gap-4 justify-around items-center'>
            <div>
            <button onClick={()=>{
              navigate('/dashboard/deposit')
              }} className='border-[#CD91FF] max-w-2/4 px-10 lg:px-16 relative py-2 rounded-3xl text-white text-base border-2 '>Atrás
              <img src={leftArrow} alt="" className='absolute top-3 left-4' />
            </button>
               </div>
               <div c>
                 <a href='https://wa.me/message/PYXMG3SKHSLSP1' className='py-[0.625rem]  px-4 lg:px-12 bg-[#CD91FF] text-sm lg:text-lg text-white rounded-3xl'>Empezar asesoría de depósito de criptos</a>
               </div>
            </div>
        </div>
        <div className='mt-8'>
            <img src={homeOffice} alt="home office" className='w-full max-w-lg' />
        </div>

    </article>
</div>
)
}

export default OthersCriptos