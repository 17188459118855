import { Navigate } from "react-router-dom";

const PublicRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div>{children}</div>
  );
};

export default PublicRoute;
