import React from 'react'
import { useEffect } from 'react'
import BankTransferForm from '../../components/BankTransfer/BankTransferForm'



const BankTransfer = () => {
    //Scroll to top
useEffect(() => {
  window.scrollTo(0, 0)
}, [])

return (
<div className='bg-[#1D1D1D]  p-4 min-h-screen lg:p-8'>
    <article className='w-full 2xl:max-w-screen-xl mx-auto mt-5 '>
    <div>
         <h2 className='font-bold text-2xl md:text-3xl '>
        Deposita fondos por transferencia bancaria con nuestros
        asesores por WhatsApp.</h2>
        <p className='text-base lg:text-xl pt-10 text-white'> Si tienes en una cuenta bancaria pesos mexicanos, soles o pesos colombianos, puedes depositar fondos a
        tu cuenta Imperium, mediante transferencia bancaria. Además, tu documento de identidad (cédula, DNI, 
        otros) debe ser de Perú, Colombia o México. 
        </p>
        <p className='text-base lg:text-xl pt-4 text-white'>El equipo de Imperium estará feliz de asesorte durante el proceso de deposito de fondos, vía <a href='https://wa.me/message/PYXMG3SKHSLSP1'  target='blank' className='text-[#F97575] underline'>WhatsApp. </a> </p>

    </div>
    <div className=' py-10 text-center'>
        <a href='https://wa.me/message/PYXMG3SKHSLSP1' target='_blank' rel='noreferrer' className='py-[10px]  text-[12px] sm:text-sm px-2 lg:px-24 xl:px-32 lg:text-base  bg-[#CD91FF] text-white rounded-lg'>Empezar asesoría de transferencia bancaria </a>
    </div>
    <div className='bg-[#949494] py-[.5px]' />
        <div className=' mt-8'>
            <h2 className='font-bold  text-2xl md:text-3xl'>Si no estoy en esos países ¿Qué puedo hacer?</h2>
            <p className='text-base lg:text-xl  pt-4 text-white '>
            Recuerda que también puedes cargar fondos a tu cuenta con tu cripto favorita. Si no sabes comprar criptos puedes pedir ayuda a nuestros asesores vía <a href='https://wa.me/message/PYXMG3SKHSLSP1' target='blank' className='text-[#F97575] underline'>WhatsApp. </a> También puedes completar los siguientes campos para considerar tu país como prioridad en nuestra siguiente actualización y notificarte por correo electrónico cuando esté listo. 
            </p>
            <BankTransferForm/>
        </div>

    </article>
</div>
)
}

export default BankTransfer