const baseUrl = process.env.REACT_APP_API_URL;

export const handleGetAllProjects = async (token) => {

    const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    }

    try {
      const url = `${baseUrl}/v1/inventory`

      const response = await fetch(url, options)

      const projects = await response.json()

      if (!projects.ok) return []

      return projects.allProjects

    } catch (error) {
      return []
    }
  }