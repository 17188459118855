import React from 'react'
import moment from 'moment/moment'
import { txIdUrl } from './urlHelper'
import confirmed from '../../Assets/Imgs/Confirmed.png'
import pending from '../../Assets/Imgs/Pending.png'
import rejected from '../../Assets/Imgs/Rejected.png'
import {BiLinkExternal} from 'react-icons/bi'


const DepositLogsInfo = ({logsInfo}) => {

const logsInfoCripto = logsInfo?.filter((criptoData => criptoData.depositType === "Crypto"))

  return (
    <div>
        {logsInfoCripto?.map((data,index) =>{
        
        
        const dateApi = data?.timestamp
        const date = moment.unix(dateApi / 1000).format('l');
        const cripto = data?.unit
        const tXID = data?.TXID
        const status = data?.status
        const amount = data?.amount


   const transactionStatus = (status) =>{
  switch (status) {
    case 'Confirmed':
      return <img src={confirmed} className='w-5 lg:w-full' alt='confirmed'/>
    case 'Pending':
      return <img src={pending} className='w-5 lg:w-full' alt='pending'/>
    case 'Rejected':
      return <img src={rejected} className='w-5 lg:w-full' alt='rejected'/>
    default:
      return;
  }
}


            return <article key={index} className='h-[4rem] sm:text-h-[5rem] items-center justify-between sm:justify-around  mt-4 bg-[#0D0D0D] flex gap-2 lg:grid text-sm text-[#949494]  rounded-2xl grid-cols-5  place-items-center  px-2 lg:px-4'>
                <div className=' flex flex-col gap-1 justify-center items-center'>
                   <div >
                     <p className='text-base text-white lg:hidden'>Fecha </p>
                   </div>
                    <div className='text-sm sm:text-base'>
                        <p>{date}</p>
                    </div>
                </div>
                <div className='flex flex-col gap-1 justify-center items-center'>
                    <p className='text-sm sm:text-base text-white lg:hidden'>
                        Moneda
                    </p>
                  <div className='sm:text-base'>
                      {cripto}
                  </div>
                </div>
                <div className='flex flex-col  gap-1 justify-center items-center'>
                    <p className='text-sm sm:text-base text-white lg:hidden'>
                        Monto
                    </p>
                <div className='text-[0.69rem] sm:text-base'>
                    {amount === 0 ? 'No verificado' : `${amount}`}
                    </div>
                </div>
                <div>
                    <div className='flex flex-col gap-1 justify-center items-center'>
                    <p className='text-sm sm:text-base text-white lg:hidden'>tXID</p>
                    <a href={txIdUrl(tXID,cripto)} target="_blank" rel="noopener noreferrer">
                        <span className= 'text-lg sm:text-xl lg:text-2xl'>
                        <BiLinkExternal/>
                        </span>
                        <span className='hidden '> {`${tXID.substring(0,7)}...${tXID.substring(7,15)}`}</span>
                    </a>
                    </div>
                  
                </div>
                <div className='flex flex-col  gap-1 justify-center items-center'>
                    <p className=' text-sm sm:text-base text-white lg:hidden'>Verificado</p>
                    <div>
                   {transactionStatus(status)}
                    </div>
                </div>
            </article>
        })}
    </div>
  )
}

export default DepositLogsInfo