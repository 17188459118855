import React, { useState, useEffect } from "react";
import "./ProjectsPage.scss";
import Card from "./Card";
import LoadingDot from "./Loading";
import WhatsappButton from "../../components/Buttons/WhatsappButton/WhatsappButton";

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${baseUrl}/v1/inventory`)
      .then((res) => res.json())
      .then((data) => {
        const productionProjects = data.allProjects
          .reverse()
          .filter((status) => {
            return status.status === "Production";
          });

        const orderedProjectsByStatus = productionProjects.sort(
          (a, b) => a.releaseStatus.statusCode - b.releaseStatus.statusCode
        );

        setProjects(orderedProjectsByStatus);
        setLoading(false);
      })
      .catch((err) => {
        setProjects([]);
        console.log(err);
      });
  }, [baseUrl]);

  return (
    <>
      <WhatsappButton />
      {/* =================== */}
      <main className="bg-[#0D0D0D] min-h-screen">
        <section className=" bg_elipsis bg-left-top bg-cover min-h-screen py-32  ">
          <div className="flex flex-col place-items-center  max-w-[1177px] mx-auto">
            <h1 className="text-2xl xl:text-3xl font-semibold xl:py-8">
              Explora nuestros activos
            </h1>
          </div>
          <hr className="border-[#D9D9D9] border-opacity-[0.2] mt-12 mx-8 lg:mx-20  2xl:mx-24 " />
          {/* <Categories filterItems={filterItems} categories={allCategories} /> */}
          <section className="bg-[#332F50] min-h-screen  max-w-[1177px]  xl:mx-auto mx-2 mt-10  p-4 rounded-lg">
            {/* <section className="bg-[#332F50] min-h-screen  max-w-[1177px]  xl:mx-auto mx-8 mt-10  p-4 rounded-lg flex justify-center align-center"> */}
            {loading ? <LoadingDot /> : <Card projects={projects} />}
          </section>
        </section>
      </main>
    </>
  );
};

export default ProjectsPage;
