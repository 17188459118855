const baseUrl = process.env.REACT_APP_API_URL;

export const isExistingUserByEmail = async (email) => {
    const url = `${baseUrl}/v1/user/account?userEmail=${email?.toLowerCase()?.trim()}`;
    try {
      const response = await fetch(url);

      const data = await response.json();
        return data

    } catch (error) {
      console.log("Error, ", error);
        return error.message
    }
  };