import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import QR from '../../Assets/Imgs/QR.svg'
import QRImage from './QRImage'
import Clipboard from '../../Assets/Imgs/clipboard.svg'
import leftArrow from '../../Assets/Imgs/leftArrow.svg'
import {depositContextData} from '../../contexts/depositContext'
import { useContext } from 'react'
import { useState } from 'react'
import SocialMedia from '../../components/Deposits/SocialMedia'
import './tooltip.css'

const StepOnetoDeposit = () => {
const [copied, setCopied] = useState(false)
const [errorMsg, setErrorMsg] = useState(false)
const {addressValue} = useContext(depositContextData)
const [addressInput] = useState(addressValue)
const [copystate,setCopyState] = useState('Copiar dirección')
const navigate = useNavigate()


return (
<div className='bg-[#1D1D1D] p-4 lg:p-8 min-h-screen'>
  <article className='w-full mt-5 mx-auto 2xl:max-w-screen-xl '>
    <div className='flex flex-col text-center justify-between pb-12  items-center lg:flex-row lg:text-left'>
      <div className='w-full'>
        <h2 className='font-bold text-2xl md:text-3xl'>
          Solo te queda un paso para depositar criptos</h2>
        <p className='text-base md:text-xl pt-4 text-[#808080] test '>
          Copia esta dirección y transfiere tus criptos desde cualquier billetera. Luego haz click en el botón "¡Ya
          deposité!"
        </p>
        <form className='flex flex-col items-center'>
          <div  className='relative '>
            <CopyToClipboard text={addressInput} onCopy={()=> setCopied(true)}>
              <button  type='button' onClick={()=>{ setCopyState('¡Copiado!')}} onMouseEnter={() => setCopyState('Copiar dirección')} >
                <input readOnly   onClick={()=>{
                  setCopied(true)
                }}  data-tooltip={copystate} value={`${addressInput}`}
              className='bg-transparent  text-ellipsis w-[16rem] md:w-full pr-12 pl-4 lg:px-32 py-4 my-6  border-[#CD91FF] cursor-pointer text-white text-lg border-2  rounded-2xl' />
                <div data-tooltip={copystate} className='tooltip'>
                  <img src={Clipboard}  className='absolute w-8  top-10 bottom-0 right-2 lg:right-4 lg:top-10  active:top-12 transition-all ' alt='clipboard' />
                </div>
              </button>
            </CopyToClipboard>
          {copied &&  <h4 className='text-center bg-[#68E365] max-w-fit px-4 md:max-w-full mx-auto rounded-md text-black text-lg font-semibold '>¡Dirección copiada con exito!</h4>}
          </div>




          <div className='mt-6 flex justify-center gap-4 lg:gap-12 items-center text-white '>
            <button onClick={()=>{
              navigate('/dashboard/deposit')
              }} className='border-[#CD91FF] max-w-2/4 px-10 lg:px-16 relative py-2 rounded-3xl text-white text-base border-2 '>Atrás
              <img src={leftArrow} alt="" className='absolute top-3 left-4' />
            </button>
           <button onClick={() => addressInput === '' ? setErrorMsg(true) : navigate('/dashboard/deposit-step2')} type='button' className='border-[#CD91FF] transition-all hover:bg-[#CD91FF]  max-w-2/4 px-5 lg:px-10 py-2 rounded-3xl text-white text-base
border-2'>¡Ya deposité!</button>

          </div>
          {errorMsg ? <p className='text-lg  text-custom-pink  mt-4'>Hubo un error al procesar tu información.</p> :
          null}
        </form>

      </div>
      <div className='px-8 mt-5 max-w-[13.75rem] lg:max-w-[20rem]'>
        <QRImage value={addressValue} />
      </div>
    </div>

    <div className='bg-[#949494] py-[.5px]' />
    <div className=' mt-8 mx-auto  text-center lg:text-left'>
      <h2 className='font-bold  text-2xl md:text-3xl'>¿A dónde transferiré mis cripto?</h2>
      <p className='text-base md:text-xl pt-4 text-[#808080] '>
        A tu cuenta en Imperium. ¡Esto nos permitirá procesar más rápido todas las compras de fracciones que hagas en
        nuestra App!
      </p>
    </div>
    
    <div className='mt-8'>
      <h2 className='font-bold text-center lg:text-left text-2xl md:text-3xl'>Síguenos en nuestras redes sociales</h2>
          <SocialMedia/>
    </div>
  </article>
</div>
)
}

export default StepOnetoDeposit