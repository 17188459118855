import React from 'react'
import { useState } from 'react'

import { NavLink } from 'react-router-dom'
import { useAuthContext } from "contexts/authContext";
import WhatsappButton from "../../components/Buttons/WhatsappButton/WhatsappButton";
import { AiOutlineBank, AiOutlineHistory } from "react-icons/ai";
import { RiHandCoinLine } from "react-icons/ri";
import { BsCurrencyBitcoin, BsCreditCard2Back} from "react-icons/bs";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";


const Sidebar = ({children}) => {

  const [isOpen] = useState(true)


    const {
    // isAuthenticated: isLoggedIn,
    logout,
    username,
  } = useAuthContext();

     

  return (
       <div className='flex    '>
      <aside className={` hidden lg:flex flex-col bg-[#0D0D0D] justify-start gap-2  overflow-x-hidden overflow-y-hidden z-30 p-4 shadow-sidebar min-h-screen transition-all text-white  ${isOpen ? 'w-[17.625rem]' : 'w-20'}`}>
        <div className='flex  mb-4 mt-20 justify-end items-center py-4 px-4 flex-row'>
          
      
        </div>
        <p className={`text-white px-2 text-center text-lg ${isOpen ? 'block' : 'hidden'}`}>
        ¡Hola <span className="text-custom-pink">{username} </span>!
      </p>
        <NavLink to='/dashboard/balance'  className={`flex text-white py-2  px-2 mt-4 items-center transition-all  rounded-md hover:bg-[#f97575a9]
          
            ${!isOpen ? 'justify-center' : null}`}>

            <div className='px-2 text-lg md:text-2xl'><MdOutlineAccountBalanceWallet/></div>
            <div className={`text-sm lg:text-base   ${isOpen ? 'block' : 'hidden'}`}>Balance</div>
          </NavLink>
        <NavLink to='/dashboard/deposit'  className={`flex text-white py-2  px-2 my-2 items-center transition-all  rounded-md hover:bg-[#f97575a9]
          
            ${!isOpen ? 'justify-center' : null}`}>

            <div className='px-2 text-lg md:text-2xl'><BsCurrencyBitcoin/></div>
            <div className={`text-sm lg:text-base ${isOpen ? 'block' : 'hidden'}`}>Depósito de criptos</div>
          </NavLink>
        <NavLink to='/dashboard/deposit-logs'  className={`flex text-white py-2  px-2 my-2 items-center transition-all  rounded-md hover:bg-[#f97575a9]
          
            ${!isOpen ? 'justify-center' : null}`}>

            <div className='px-2 text-lg md:text-2xl'><AiOutlineHistory/></div>
            <div className={`text-sm  lg:text-base ${isOpen ? 'block' : 'hidden'}`}>Historial de depósitos</div>
          </NavLink>
        <NavLink to='/dashboard/bank-transfer'  className={`flex text-white py-2  px-2 my-2 items-center transition-all  rounded-md hover:bg-[#f97575a9]
          
            ${!isOpen ? 'justify-center' : null}`}>

            <div className='px-2 text-lg md:text-2xl'><AiOutlineBank/></div>
            <div className={`text-sm lg:text-base ${isOpen ? 'block' : 'hidden'}`}>Transferencia Bancaria</div>
        </NavLink>

        <NavLink to='/dashboard/withdraw-funds'  className={`flex text-white py-2  px-2 my-2 items-center transition-all  rounded-md hover:bg-[#f97575a9]
          
            ${!isOpen ? 'justify-center' : null}`}>

            <div className='px-2 text-lg md:text-2xl'><RiHandCoinLine/></div>
            <div className={`text-sm lg:text-base  ${isOpen ? 'block' : 'hidden'}`}>Retiro de fondos</div>
        </NavLink>
          
        <button disabled={true}  className={`text-left w-full flex text-white py-2 px-2 my-2 items-center transition-all  rounded-md
            ${!isOpen ? 'justify-center' : null}`}>
            <div className=' text-[#949494] px-2 text-lg md:text-2xl'><BsCreditCard2Back/></div>
            <div className={`text-sm lg:text-base  text-[#949494]   ${isOpen ? 'block' : 'hidden'}`}>Tarjeta de Crédito/Debito</div>
          </button>

        <button onClick={logout} className={`text-left w-full flex text-white mx-auto py-2 px-2 my-2 items-center transition-all  rounded-md hover:bg-[#f97575a9]
            ${!isOpen ? 'justify-center' : null}`}>
            <div className=' px-2 text-lg md:text-2xl'><BiLogOut/></div>
            <div className={`text-sm lg:text-base ${isOpen ? 'block' : 'hidden'}`}>Cerrar sesión</div>
          </button>
      </aside>
      <main className='w-full lg:mt-[5.5rem]   text-white'>
      <WhatsappButton />

        {children}</main>
    </div>
  )
}

export default Sidebar