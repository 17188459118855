import { GoThreeBars } from "react-icons/go";
import Logo from "../../Assets/Imgs/Logo.png";
import { VscClose } from "react-icons/vsc";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import DashboardMenu from "./DashboardMenu";
import { Avatar, Dropdown } from "flowbite-react";
import { useAuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";

const DashboardNav = () => {
  const [menu, setMenu] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    logout,
    username,
    email,
  } = useAuthContext();

  const handleMenu = () => {
    setMenu(!menu);
  };

  const navigate = useNavigate();

  const toDeposit = () => {
    navigate("/dashboard/deposit");
  };

  const toDashBoard = () => {
    navigate("/dashboard/balance");
  };

  return (
    <nav className="w-full sticky top-0 lg:fixed z-50 py-4 px-4 bg-[#0D0D0D]  lg:px-6 lg:py-6 ">
      <div className="flex justify-between lg:justify-between items-center lg:pr-20">
        <div>
          <NavLink to="/">
            <img src={Logo} alt="imperiumLogo" className="h-8 xl:h-10" />
          </NavLink>
        </div>
        <button className=" lg:hidden" onClick={handleMenu}>
          {menu ? (
            <VscClose onClick={handleMenu} className="text-red-400 text-3xl" />
          ) : (
            <GoThreeBars
              onClick={handleMenu}
              className="text-red-400 text-3xl"
            />
          )}
        </button>
        <div className="hidden lg:block">
          <DashboardMenu handleMenu={handleMenu} />
        </div>

        <div className=" hidden lg:flex justify-center items-center gap-12 ">
          <div>
            <a
              href="https://115vjf9y60p.typeform.com/bugreporting"
              target="blank"
              className="menu-link py-2 px-4  border  border-red-400 rounded-xl transition-all hover:bg-red-400 menu-link-desktop navbar-shadow"
            >
              Reportar problemas
            </a>
          </div>
          <div>
            <NavLink to="/">
              <span className="bg-custom-pink  rounded-xl px-6 text-white py-2 navbar-shadow">
                Explorar
              </span>
            </NavLink>
          </div>
          {isLoggedIn ? (
            <Dropdown
              label={<Avatar alt="User settings" img="" rounded={true} />}
              arrowIcon={false}
              inline={true}
              style={{
                color: "#00sf",
              }}
            >
              <Dropdown.Header>
                {/* <span className="block text-sm">{user.username}</span> */}
                <div className="text-left text-sm">{username}</div>
                <div className="text-left text-sm font-medium truncate">
                  {/* {user.email} */}
                  {email}
                </div>
              </Dropdown.Header>
              <Dropdown.Item onClick={toDashBoard}>Dashboard</Dropdown.Item>
              <Dropdown.Item onClick={toDeposit}>
                Depositar fondos
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}>Salir</Dropdown.Item>
            </Dropdown>
          ) : (
            <div className="flex gap-8 xl:gap-16">
              <li>
                <NavLink to="/auth/register">
                  <span className="menu-link menu-link-desktop navbar-shadow ">
                    Registrarse
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/auth/login">
                  <span className="menu-link menu-desktop-login navbar-shadow">
                    Iniciar sesión
                  </span>
                </NavLink>
              </li>
            </div>
          )}
        </div>
      </div>

      {/* menu-link | show-menu from input.css */}
      <div
        className={`${menu ? "menu-links show-menu-dashboard" : "menu-links"}`}
      >
        <DashboardMenu handleMenu={handleMenu} />
      </div>
    </nav>
  );
};

export default DashboardNav;
