import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ReactComponent as Padlock } from "../../../Assets/Icons/Padlock.svg";
import { ReactComponent as Check } from "../../../Assets/Icons/Check.svg";
import LoadingPage from "../../LoadingPage/LoadingPage";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";
import Logo from "../../../components/Logo/Logo";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Crea una contraseña")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.* ).{8,}$/,
      "Tu contraseña debe tener mínimo 8 caracteres: alfanuméricos, especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios entre ellos"
    ),
  confirmPassword: yup
    .string()
    .required("")
    .oneOf([yup.ref("password"), null], "Ambas contraseñas deben ser iguales")
    .required('Debes rellenar el campo "Confirma tu contraseña"'),
});

const baseUrl = process.env.REACT_APP_API_URL;

const NewPasswordPage = () => {
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [isHiddenPasswordConfirm, setIsHiddenPasswordConfirm] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { email, token } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    const handleCheckingUser = async () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };

    handleCheckingUser();
  }, [email]);

  const handleToggle = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handletoggleConfirm = () => {
    setIsHiddenPasswordConfirm(!isHiddenPasswordConfirm);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const onSubmit = async ({ password }) => {
    setIsSubmitting(true);
    setErrorMsg(null);

    const updateUrl = `${baseUrl}/v1/auth/reset-password`;

    try {
      const response = await fetch(updateUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ token, password }),
      });

      const data = await response.json();

      if (data.ok) {
        navigate(`/auth/successful-password-reset/${email}`);
      }

      setErrorMsg("Ha ocurrido un error, intenta de nuevo");
      setIsSubmitting(false);

      setTimeout(() => {
        setErrorMsg(null);
      }, 3000);
    } catch (error) {
      console.log("error, ", error);
      setIsSubmitting(false);
      setErrorMsg("Ha ocurrido un error, intenta de nuevo");
      setIsSubmitting(false);

      setTimeout(() => {
        setErrorMsg(null);
      }, 3000);
    }
  };

  return (
    <div className="min-h-screen flex">
      <LoadingPage isLoading={isLoading} />
      {/* -----------------------------   Start image section  ----------------------------*/}
      <WelcomeLoginImage />
      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}

      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-2/5">
          <h1 className="lg:block text-3xl md:text-4xl font-extrabold mb-6 mt-5 font-anek-tamil">
            Elige una contraseña nueva
          </h1>

          <p className="hidden lg:block font-poppins font-light text-custom-gray-light mb-8 lg:mb-5 xl:mb-8 ">
            Tu contraseña debe tener mínimo 8 caracteres: alfanuméricos,
            especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios entre
            ellos
          </p>

          {/* -----------------------------   Start form section  ----------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* --------Password---------- */}

            <div className="mb-4   ">
              {errors.password && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.password?.message}
                </div>
              )}

              <div className="relative ">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Padlock className="text-custom-gray w-6 h-6" />
                </div>

                <input
                  {...register("password")}
                  type={isHiddenPassword ? "password" : "text"}
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full px-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input ${
                    !errors.password && touchedFields.password
                      ? "text-custom-purple"
                      : "text-white"
                  } focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Contraseña"
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0 cur">
                  {isHiddenPassword ? (
                    <AiOutlineEyeInvisible
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handleToggle}
                    />
                  ) : (
                    <AiOutlineEye
                      className="text-custom-gray-light--input w-6 h-6  cursor-pointer"
                      onClick={handleToggle}
                    />
                  )}
                </div>

                <div className="flex absolute inset-y-0 items-center right-14 right-0">
                  {errors.password?.message !== "empty" &&
                    errors.hasOwnProperty("password") &&
                    touchedFields.password}
                  {!errors.hasOwnProperty("password") &&
                    touchedFields.password && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )}
                  {errors.password?.message === "empty" &&
                    touchedFields.password}
                </div>
              </div>
            </div>

            {/* --------Confirm password---------- */}

            <div className="mb-4 ">
              {errors.confirmPassword?.message !== "empty" &&
                errors.hasOwnProperty("confirmPassword") && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.confirmPassword?.message}
                  </div>
                )}
              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Padlock className="text-custom-gray w-6 h-6" />
                </div>

                <input
                  {...register("confirmPassword")}
                  type={isHiddenPasswordConfirm ? "password" : "text"}
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full px-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input ${
                    !errors.confirmPassword && touchedFields.confirmPassword
                      ? "text-custom-purple"
                      : "text-white"
                  } focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Confirmar contraseña"
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  {isHiddenPasswordConfirm ? (
                    <AiOutlineEyeInvisible
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handletoggleConfirm}
                    />
                  ) : (
                    <AiOutlineEye
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handletoggleConfirm}
                    />
                  )}
                </div>

                <div className="flex absolute inset-y-0 items-center right-14 right-0">
                  {errors.confirmPassword?.message !== "empty" &&
                    errors.hasOwnProperty("confirmPassword") &&
                    touchedFields.confirmPassword}
                  {!errors.hasOwnProperty("confirmPassword") &&
                    touchedFields.confirmPassword && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )}
                  {errors.confirmPassword?.message === "empty" &&
                    touchedFields.confirmPassword}
                </div>
              </div>
            </div>

            {/* --------Terms and conditions---------- */}

            {/* --------Submit button---------- */}

            <div className="flex flex-col justify-center xl:mt-2 2xl:mt-16">
              {errorMsg && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errorMsg}
                </div>
              )}

              <button
                disabled={isSubmitting}
                type="submit"
                className="flex justify-center items-center disabled:hover:bg-custom-purple  disabled:cursor-not-allowed disabled:opacity-75 font-anek-tamil font-semibold  h-12 sm:h-16 lg:h-14 2xl:h-16 small-devices-360:text-lg text-xl  shadow-custom-purple/20 shadow-[5px_5px_5px_rgba(0,0,0,0.25)] text-white bg-custom-purple w-full hover:bg-custom-purple-dark focus:ring-4 focus:outline-none focus:ring-custom-purple-dark rounded-lg px-6 text-center"
              >
                {isSubmitting ? (
                  <div className="flex justify-center items-center">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 small-devices-820:w-5 small-devices-820:h-5 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Cargando...</span>
                  </div>
                ) : (
                  "Continuar"
                )}
              </button>
            </div>
          </form>

          <div className="mt-6 small-devices-390:mt-10 mb-5 md:mt-20 flex justify-center flex-col items-center">
            <label
              onClick={() => navigate("/auth/login")}
              className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
            >
              Atrás
            </label>
            <Logo size={"small"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordPage;
