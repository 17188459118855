// React
import React,{useState} from 'react'
//FlowBite
import { Modal,Button,Table } from 'flowbite-react'
//icons
import {AiOutlineQuestionCircle} from 'react-icons/ai'

const HowIsBeingCalculated = () => {
//Show Modal
const [show,setShow] = useState(false)

//Show Modal Functions
const onClick = () =>{
setShow(true)
}

const onClose = () =>{
setShow(false)
}



return (
<section className='flex items-center'>
    <h2 className='text-3xl font-bold'>Tus activos</h2>
    <>


        <Button style={{display:'block', background:'transparent',outline:'transparent',outlineColor:'transparent',boxShadow:'none'}} type="button" data-modal-toggle="default-modal"
            onClick={onClick}>
            <AiOutlineQuestionCircle className='text-3xl' />
        </Button>
     
       
        <Modal style={{height:'100%',animation:'ease-in',animationDuration:2}} size='4xl' show={show} onClose={onClose} >
            <Modal.Header
                style={{display:'flex', alignItems:'center', fontWeight:'600', justifyContent:'space-between', padding: '1rem', borderBottom:'0.0625rem', borderTopLeftRadius:'0.25rem', borderTopRightRadius:'0.25rem'}}>
                <p style={{paddingRight:'0.375rem', paddingLeft:'0.375rem', paddingTop:'0.25rem', color:'#111827', fontWeight:600, fontSize:'1.5rem'}}>
                    Ok pero... ¿Cómo funciona esto?</p>
            </Modal.Header>
            <Modal.Body>
                <Table>

                    <Table.Head style={{backgroundColor: '#F9FAFB', fontSize: '18px', color: '#374151' }}>
                        <Table.HeadCell>
                            Título
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Definición
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Formula
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Unidad
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y text-black ">
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                Cantidad de fracciones
                            </Table.Cell>
                            <Table.Cell>
                                Cantidad de fracciones que compró el usuario para ese activo en específico.
                            </Table.Cell>
                            <Table.Cell>
                                Cantidad de fracciones = Q
                            </Table.Cell>
                            <Table.Cell>
                                Fracciones
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                Total invertido
                            </Table.Cell>
                            <Table.Cell>
                                Monto total que pagó el usuario por la cantidad de fracciones que ha comprado.
                            </Table.Cell>
                            <Table.Cell>
                                Total invertido = Q x $10
                            </Table.Cell>
                            <Table.Cell>
                                $USD
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                Valor actual
                            </Table.Cell>
                            <Table.Cell>
                                Valor de mercado del total de fracciones compradas por el usuario.
                            </Table.Cell>
                            <Table.Cell>
                                Valor actual = Floor price x ETH price (in USDT) x Q
                            </Table.Cell>
                            <Table.Cell>
                                $USD
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                Retorno
                            </Table.Cell>
                            <Table.Cell>
                                Ganancia o pérdida de la inversión.
                            </Table.Cell>
                            <Table.Cell>
                                Retorno = valor actual - total invertido
                            </Table.Cell>
                            <Table.Cell>
                                $USD
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                Retorno
                            </Table.Cell>
                            <Table.Cell>
                                Ganancia o pérdida de la inversión.
                            </Table.Cell>
                            <Table.Cell>
                                Retorno = ((valor actual - total invertido) / total invertido) x100%
                            </Table.Cell>
                            <Table.Cell>
                                %
                            </Table.Cell>

                        </Table.Row>
                    </Table.Body>
                </Table>

            </Modal.Body>


        </Modal>

    </>

</section>
)
}

export default HowIsBeingCalculated