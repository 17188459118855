import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WhatsappButton from "../../components/Buttons/WhatsappButton/WhatsappButton";
import ProjectDetails from "./ProjectDetails";

const ProjectPage = () => {
  const [detailInfo, setDetailInfo] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;
  const { id: idProject } = useParams();
  const url = `${baseUrl}/v1/inventory/${idProject}`;

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setDetailInfo(data.onlyProject);
      })
      .catch((err) => {
        console.log(err);
        setDetailInfo(null);
      });
  }, [url]);

  return (
    <>
      <WhatsappButton />
      {/* <section className=" bg-[#0D0D0D] bg_elipsis bg-left-top bg-cover py-32 min-h-screen  "> */}
      <section className=" bg-[#0D0D0D] bg_elipsis bg-left-top bg-cover min-h-screen ">
        {detailInfo && (
          <ProjectDetails setDetailInfo={detailInfo}></ProjectDetails>
        )}
      </section>
    </>
  );
};

export default ProjectPage;
