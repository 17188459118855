import { NavLink } from "react-router-dom";

import { useAuthContext } from "../../contexts/authContext";
import "./DropdownToolTip.scss";

const NavbarMenu = () => {
  const { isAuthenticated: isLoggedIn, logout } = useAuthContext();

  return (
    <ul className="font-Anek items-center  flex flex-col justify-between gap-4 py-4 text-left lg:flex lg:flex-row  lg:gap-8 lg:text-center xl:gap-16">
      <li>
        <a
          href="https://115vjf9y60p.typeform.com/bugreporting"
          target="blank"
          className="menu-link py-2 px-4 border  border-red-400 rounded-xl transition-all hover:bg-red-400 menu-link-desktop navbar-shadow"
        >
          Reportar problemas
        </a>
      </li>
      {isLoggedIn && (
        <>
          <li>
            <NavLink to="/dashboard/balance">
              <span className="bg-custom-pink  rounded-xl px-6 text-white py-2 navbar-shadow">
                Dashboard
              </span>
            </NavLink>
          </li>
          <li>
            <button
              className="bg-custom-pink  rounded-xl px-6 text-white py-2 navbar-shadow"
              onClick={logout}
            >
              Cerrar sesión
            </button>
          </li>
        </>
      )}

      {!isLoggedIn && (
        <div className="flex gap-8 xl:gap-16">
          <li>
            <NavLink to="/auth/register">
              <span className="menu-link menu-link-desktop navbar-shadow ">
                Registrarse
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/auth/login">
              <span className="menu-link menu-desktop-login navbar-shadow">
                Iniciar sesión
              </span>
            </NavLink>
          </li>
        </div>
      )}
    </ul>
  );
};

export default NavbarMenu;
