import React, { useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { AuthRouter } from "./AuthRouter";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { ProjectsRouter } from "./ProjectsRouter";
import { DashboardRouter } from "./DashboardRouter";
import { useAuthContext } from "../contexts/authContext";
import CreatedAccountPage from "../pages/authPages/CreatedAccountPage/CreatedAccountPage";
import NewPasswordPage from "../pages/authPages/RecoveryAccountPage/NewPasswordPage";
import ResetPasswordPage from "../pages/authPages/RecoveryAccountPage/ResetPasswordPage";
import VerifiedAccountPage from "../pages/authPages/VerifiedAccountPage/VerifiedAccountPage";

export const AppRouter = () => {
  const { isAuthenticated: isLoggedIn } = useAuthContext();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_URL;
    console.log("baseUrl ==== ", baseUrl);

    console.log("CWD, ", process.env.PUBLIC_URL);

    const getUrl = window.location;
    const baseUrl2 = getUrl.protocol + "//" + getUrl.host;
    console.log("window location baseUrl:, ", baseUrl2);
  }, []);

  const pathServer = ""; // Test

  return (
    <Router>
      <Routes>
        <Route path={`${pathServer}/*`} element={<ProjectsRouter />} />

        <Route
          path={`${pathServer}auth/*`}
          element={
            <PublicRoute isLoggedIn={isLoggedIn}>
              <AuthRouter />
            </PublicRoute>
          }
        />

        <Route path="created-account/:email" element={<CreatedAccountPage />} />
        <Route
          path="new-password/:email/:token"
          element={<NewPasswordPage />}
        />
        <Route path="reset-password/:email" element={<ResetPasswordPage />} />
        <Route
          path="verified-account/:email/:token"
          element={<VerifiedAccountPage />}
        />

        <Route
          path={`${pathServer}dashboard/*`}
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <DashboardRouter />
            </PrivateRoute>
          }
        />

        {/* <Route
          path={`${pathServer}checkout/*`}
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <CheckoutRouter />
            </PrivateRoute>
          }
        /> */}
      </Routes>
    </Router>
  );
};
