import { NavLink } from "react-router-dom";
import "../../components/Navbar/DropdownToolTip.scss";
import { useAuthContext } from "../../contexts/authContext";
import "./Dashboard.css";
import { AiOutlineBank, AiOutlineHistory } from "react-icons/ai";
import {
  BsCurrencyBitcoin,
  BsCreditCard2Back,
  BsBug,
  BsPerson,
} from "react-icons/bs";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { BiLogOut, BiLogIn } from "react-icons/bi";
import { RiHandCoinLine } from "react-icons/ri";

const DashboardMenu = ({ handleMenu }) => {
  const { logout, username, isAuthenticated: isLoggedIn } = useAuthContext();

  const menuItem = [
    {
      path: "/dashboard/balance",
      name: "Balance",
      icon: <MdOutlineAccountBalanceWallet />,
    },
    {
      path: "/dashboard/deposit",
      name: "Depósito de criptos",
      icon: <BsCurrencyBitcoin />,
    },
    {
      path: "/dashboard/deposit-logs",
      name: "Historial de depósitos",
      icon: <AiOutlineHistory />,
    },
    {
      path: "/dashboard/bank-transfer",
      name: "Transferencia Bancaria",
      icon: <AiOutlineBank />,
    },
    {
      path: "/dashboard/withdraw-funds",
      name: "Retiro de fondos",
      icon: <RiHandCoinLine />,
    },
  ];

  return (
    <ul className="flex flex-col justify-between gap-2 py-8 px-2  lg:hidden">
      {isLoggedIn && (
        <p className="text-white">
          ¡Hola <span className="text-custom-pink">{username} </span>!
        </p>
      )}
      <div className="bg-[#949494] py-[.5px] mb-2 "></div>
      {isLoggedIn && (
        <div>
          {menuItem.map((item, index) => {
            return (
              <NavLink
                key={index}
                className={` flex items-center transition-all gap-2 just hover:bg-[#f97575a9]  text-sm  rounded-lg p-1`}
                to={item.path}
                onClick={() => {
                  handleMenu();
                }}
              >
                <div className="text-white text-xl ">{item.icon}</div>
                <span className=" text-white py-2 navbar-shadow ">
                  {item.name}
                </span>
              </NavLink>
            );
          })}
        </div>
      )}
      {isLoggedIn && (
        <li className="flex items-center p-1">
          <div>
            <div className="text-white text-xl ">
              <BsCreditCard2Back color="#878789" />
            </div>
          </div>
          <button
            disabled
            className="text-[#878789] py-2 navbar-shadow  text-sm rounded-xl p-2 disabled"
          >
            Tarjeta de Crédito/Debito{" "}
            <span className="text-[0.725rem] font-semibold text-black  p-[0.1875rem] rounded-lg bg-[#878789]">
              pronto
            </span>
          </button>
        </li>
      )}
      <li
        className={`flex items-center  cursor-pointer transition-all gap-2 just hover:bg-[#f97575a9]  text-sm  rounded-lg p-1`}
      >
        <div className="text-white text-xl ">
          <BsBug />
        </div>
        <a
          className=" text-white py-2 navbar-shadow"
          href="https://115vjf9y60p.typeform.com/bugreporting"
          target="blank"
        >
          Reportar problemas
        </a>
      </li>
      {isLoggedIn && (
        <li
          className={`flex items-center  cursor-pointer transition-all gap-2 just hover:bg-[#f97575a9]  text-sm  rounded-lg p-1`}
          onClick={logout}
        >
          <div className="text-white text-xl ">
            <BiLogOut />
          </div>
          <span className=" text-white py-2 navbar-shadow ">Cerrar sesión</span>
        </li>
      )}
      {!isLoggedIn && (
        <>
          <NavLink
            to="/auth/register"
            className={`flex items-center transition-all gap-2 just hover:bg-[#f97575a9]  text-sm  rounded-lg p-1`}
          >
            <div className="text-white text-xl  ">
              <BsPerson />
            </div>
            <span className=" text-white py-2 navbar-shadow ">Registrarse</span>
          </NavLink>
          <NavLink
            to="/auth/login"
            className={`flex  items-center transition-all gap-2 just hover:bg-[#f97575a9]  text-sm  rounded-lg p-1`}
          >
            <div className="text-white text-xl ">
              <BiLogIn />
            </div>
            <span className=" text-white py-2 navbar-shadow ">
              Iniciar sesíon
            </span>
          </NavLink>
        </>
      )}
    </ul>
  );
};

export default DashboardMenu;
