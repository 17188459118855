import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "./ModalTerms.scss";

const ModalTerms = ({ isOpenModal, closeModal, handleAcceptTerms }) => {
  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full w-11/12 max-w-2xl transform text-left rounded-2xl transition-all shadow-xl">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-2xl shadow overflow-hidden h-1/5">
                  <div className="bg-custom-purple flex justify-between items-center py-6 px-8 border-b  ">
                    <h3 className="text-lg font-lato font-semibold text-white">
                      Términos y condiciones
                    </h3>

                    <button
                      onClick={closeModal}
                      type="button"
                      className="focus:outline-none hover:bg-custom-purple-dark rounded-lg p-1.5"
                    >
                      <svg
                        className="w-5 h-5 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="modal-body p-6 space-y-6 h-[25rem] overflow-y-auto ">
                    {/* Resumen General */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      En IMPERIUM, nos comprometemos a democratizar el acceso al
                      mercado de activos digitales en todo el mundo
                      (representados a través de NFTs) para que todas las
                      personas puedan acceder al mismo a través de una
                      experiencia de usuario simple y sencilla, que les permita
                      tomar decisiones informadas sin tener que comprometer gran
                      parte de su capital. Queremos asegurarnos de que nuestras
                      interacciones con los USUARIOS de IMPERIUM sean
                      transparentes. Por lo tanto, hemos resumido los puntos más
                      importantes de los términos y condiciones que nuestros
                      USUARIOS aceptan al acceder a nuestra plataforma digital
                      y/o adquirir/usar nuestros productos/servicios:
                    </p>
                    <ul className="list-disc list-inside">
                      <br />
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        El producto que ofrece IMPERIUM se denomina FRACCIÓN,
                        que representa propiedad proporcional sobre los derechos
                        económicos de uno o un conjunto de ACTIVOS DIGITALES.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        IMPERIUM no es una entidad vigilada o sometida al
                        control, vigilancia e inspección de cualquier ente
                        regulatorio financiero gubernamental. Los servicios que
                        IMPERIUM presta a sus usuarios no se deben considerar
                        como financieros ni como asesoría financiera.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Cada USUARIO asume en su totalidad el riesgo que implica
                        la utilización de los servicios de IMPERIUM
                        especificados a lo largo del documento, tales como la
                        volatilidad del precio de los ACTIVOS DIGITALES y la
                        pérdida de la custodia de los activos por intervenciones
                        maliciosas.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Utilizar nuestros servicios requerirá que los usuarios
                        verifiquen su identidad mediante el compartimiento de
                        información personal y privada. Al utilizar nuestros
                        servicios, autorizas a que tratemos tus datos
                        personales. IMPERIUM hará su mejor esfuerzo para manejar
                        cuidadosamente y de manera segura tu información.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Nos reservamos el derecho, a nuestra discreción, de
                        cambiar estos Términos y Condiciones Generales de Uso
                        sin previo aviso. En caso de algún cambio sustancial,
                        haremos todos los esfuerzos razonables para informarlo a
                        todos nuestros usuarios.
                      </li>
                    </ul>

                    {/* Definición del servicio */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM se dedica a vender propiedad parcial sobre los
                      derechos económicos de ACTIVOS DIGITALES. Nos encargamos
                      de proveer infraestructura para facilitar el acceso a la
                      obtención de propiedad parcial sobre los derechos
                      económicos de ACTIVOS DIGITALES y custodiar estos activos.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      El producto principal de IMPERIUM está denominado como
                      FRACCIÓN. Una FRACCIÓN consiste en un instrumento contable
                      utilizado para representar propiedad sobre los derechos
                      económicos de ACTIVOS DIGITALES.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Un USUARIO se define como aquella persona que compra
                      fracciones de cualquier ACTIVO DIGITAL a IMPERIUM. El
                      USUARIO, al utilizar nuestras plataformas digitales y/o
                      transferir criptomonedas a IMPERIUM para la compra de las
                      mismas, acepta comprender y estar conforme con los
                      términos y condiciones redactados en el presente documento
                      (referidos en adelante como ACUERDO).
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      El servicio que brinda IMPERIUM está definido por las
                      siguientes etapas:
                    </p>
                    <ul className="list-disc list-inside">
                      <br />
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Primero, hacemos nuestro mejor esfuerzo para identificar
                        las mejores oportunidades de compra de ACTIVOS DIGITALES
                        y los adquirimos. Buscamos seleccionar activos con las
                        mejores propuestas de valor en el largo plazo.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Segundo, IMPERIUM ofrece la venta de fracciones de los
                        mismos en su página web, redes sociales o a través de
                        correo electrónico a cambio de criptomonedas.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Por el servicio brindado, cobramos un margen de
                        beneficio de 5% (denominado Sourcing Fee) sobre el
                        precio del activo. Es decir, si el activo cuesta 1 ETH,
                        el monto total resultante de la suma pagada por los
                        USUARIOS por el total de FRACCIONES de ese activo será
                        de 1.05 ETH.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Cada USUARIO que haya adquirido fracciones posee un
                        porcentaje de la propiedad sobre los derechos económicos
                        de dichos ACTIVOS DIGITALES, que se calcula como el
                        número de FRACCIONES que el USUARIO posee dividido por
                        el número de FRACCIONES totales que se vendieron.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        IMPERIUM es responsable de coordinar, entre los
                        propietarios de los ACTIVOS DIGITALES, las decisiones a
                        tomar sobre los mismos en el futuro (tales como la venta
                        de dichos activos), y ejecutarlos en caso más del 50% de
                        los USUARIOS que participen en la votación (tomando en
                        cuenta la cantidad de FRACCIONES de cada uno) lleguen a
                        un acuerdo.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Al venderse los ACTIVOS DIGITALES, los fondos se
                        distribuirán entre todos los usuarios dependiendo de la
                        cantidad de fracciones que cada uno posea.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        IMPERIUM podrá utilizar los ACTIVOS DIGITALES, antes de
                        que sean liquidados, para generar rentabilidad (mediante
                        el alquiler de dichos activos, staking, implementación
                        de servicios en metaversos, etc). Dicha rentabilidad se
                        repartirá 50%-50% entre USUARIOS e IMPERIUM.
                      </li>
                    </ul>

                    {/* Responsabilidades limitadas de las partes  */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM está actualmente construyendo el producto digital
                      (
                      <a
                        className="text-custom-purple"
                        href="https://imperium.cool/"
                      >
                        https://imperium.cool/
                      </a>
                      ) en donde el usuario podrá interactuar para poder comprar
                      FRACCIONES, visualizar cuantas tiene en su poder, el valor
                      de las mismas, el porcentaje de propiedad que estas
                      acumulan, sistemas de votación entre los propietarios para
                      la toma de decisiones (respecto a compra y venta), entre
                      otras funcionalidades. Nos comprometemos a mantener
                      informado sobre estos puntos a los USUARIOS lo más
                      frecuentemente que nos sea posible (mediante reportes
                      automatizados, o mediante versiones beta de la página
                      web).
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM se compromete a custodiar los ACTIVOS DIGITALES
                      utilizando las mejores medidas de seguridad que le sean
                      posible para proteger los activos de robos o pérdidas (por
                      ejemplo, utilizando billeteras físicas desconectadas de la
                      red con altos estándares de seguridad, denominadas COLD
                      WALLETS).
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      No obstante, el USUARIO reconoce que los activos pueden
                      darse en pérdida por diferentes siniestros, tales como el
                      malfuncionamiento o interferencias al propio sistema de
                      blockchain en donde los activos residen, hackeos o robos
                      directos a las billeteras en donde IMPERIUM custodia los
                      ACTIVOS DIGITALES, o en los protocolos descentralizados en
                      donde IMPERIUM los coloca para realizar staking, entre
                      otros. El USUARIO exime de responsabilidad a IMPERIUM, en
                      caso dichos eventos ocurran, de recuperar o compensar a
                      los mismos por la pérdida de dichos ACTIVOS DIGITALES.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM ha desarrollado un marco analítico que le permite
                      comparar diferentes ACTIVOS DIGITALES y le permite
                      distinguir a aquellos activos con valor intrínseco (que
                      tienen mayores probabilidades de mantener o aumentar su
                      valor en el largo plazo) respecto a activos que carecen de
                      una propuesta de valor clara. IMPERIUM sólo ofrecerá a sus
                      clientes ACTIVOS DIGITALES filtrados con dicho marco. No
                      obstante, el USUARIO reconoce que estos son activos de
                      alta volatilidad y riesgo, y por lo tanto entiende que su
                      dinero está en riesgo al adquirir propiedad fraccional de
                      ACTIVOS DIGITALES. IMPERIUM no garantiza ningún resultado
                      de ganancia o pérdida de capital, y es el USUARIO quien
                      asume dicho riesgo.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Por otro lado, el USUARIO reconoce y entiende que IMPERIUM
                      no hace ninguna recomendación con respecto a cualquier
                      decisión de inversión tomada con respecto a, cualquier
                      valor, y está proporcionando al mismo ningún tipo de
                      asesoramiento financiero. Este también reconoce que
                      IMPERIUM no proporciona ni proporcionará al USUARIO ningún
                      tipo de asesoramiento legal, fiscal, de planificación
                      patrimonial o contable.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      En consecuencia, es de responsabilidad y obligación del
                      USUARIO de reportar a las autoridades tributarias
                      correspondientes respecto a las ganancias de capital que
                      el USUARIO pueda tener al vender las criptomonedas que
                      adquiera de la venta de los ACTIVOS DIGITALES. IMPERIUM se
                      reserva el derecho de reportar cualquier tipo de
                      información sobre las operaciones del USUARIO en caso una
                      autoridad tributaria lo exija.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Por último, el USUARIO acepta asumir todos los costos de
                      transacciones (o “gas fees”) que se deriven de la compra y
                      venta de activos digitales, repartición de criptomonedas
                      entre propietarios del ACTIVO DIGITAL y cualquier otra
                      operación requerida para proveer el servicio.
                    </p>

                    {/* Quienes pueden usar nuestros servicios? */}
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Para acceder a la plataforma digital o a la compra de
                      FRACCIONES de IMPERIUM, nuestros USUARIOS deberán ser
                      personas naturales, mayores de edad (según esto se defina
                      en el país en donde te encuentras) y deberán contar con
                      plena capacidad legal para obligarse contractualmente.
                      Igualmente, no podrás estar reportado en la lista OFAC,
                      dado que no aceptamos como USUARIOS a personas que tengan
                      este tipo de reportes.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Para corroborar dichas condiciones, IMPERIUM requerirá que
                      el USUARIO brinde información detallada sobre su identidad
                      y sobre la procedencia de sus fondos (proceso conocido
                      como Know-Your-Customer). La información específica que
                      IMPERIUM demande puede variar en el futuro, y si el
                      USUARIO se niega a brindar dicha información o a
                      actualizarla, entonces podrá perder el acceso al servicio,
                      o inclusive la propiedad de sus FRACCIONES.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM se reserva el derecho de interrumpir el acceso al
                      servicio o a tomar otras medidas que considere necesarias
                      en caso se detecte que un usuario ha brindado información
                      falsa respecto de su identidad (como su edad), si comienza
                      a figurar en este tipo de listas o si sospecha que la
                      procedencia de los fondos de dicho USUARIO está
                      relacionada a actividades económicas ilegales, tales como
                      el lavado de activos.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM también se reserva el derecho de excluir,
                      suspender o finalizar (sin derecho a reembolso) el acceso
                      a los servicios a un USUARIO que tenga una conducta que
                      pueda ser perjudicial para el funcionamiento del software
                      en uso, la reputación o el bienestar de IMPERIUM o de
                      otros USUARIOS, o que haya violado las disposiciones de
                      este acuerdo.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      A cada USUARIO se le asignará un nombre de identificación
                      y una contraseña únicos para el acceso y uso del Servicio
                      ("ID de Usuario"). El ID de Usuario como la contraseña son
                      de carácter personal. Por lo tanto, al acceder al App, el
                      USUARIO se compromete a mantener en absoluta reserva esta
                      información y a no compartirla ni divulgarla. De infringir
                      las obligaciones de reserva de las credenciales, se
                      considerará que existe un incumplimiento de los presente
                      ACUERDO.
                    </p>

                    {/* Tratamiento de datos personales y privacidad del usuario */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      El USUARIO otorga a IMPERIUM una licencia para copiar y
                      utilizar toda data de identificación personal o aquella
                      que se genera de la utilización del servicio (es decir,
                      del uso de la plataforma digital o de la compra de
                      FRACCIONES) para que IMPERIUM pueda:
                    </p>
                    <ul className="list-disc list-inside">
                      <br />
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Conocer mejor las preferencias y necesidades de sus
                        USUARIOS.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Ofrecer productos y servicios adicionales a los mismos.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Verifica la identidad de los mismos.
                      </li>
                    </ul>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      El USUARIO acepta que IMPERIUM puede proporcionar la
                      información del USUARIO a los proveedores de servicios de
                      terceros que trabajan en nombre de o con IMPERIUM para
                      proporcionar algunas de las características de los
                      servicios y para ayudar a IMPERIUM a comunicarse con
                      usted. Sin embargo, estos Proveedores de Servicios no
                      tienen ningún derecho independiente para compartir esta
                      información del USUARIO (excepto en virtud de un requisito
                      legal, como una citación u orden judicial).
                    </p>

                    {/* Disclamer de garantías */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      El acceso a los servicios de IMPERIUM se proporciona "tal
                      cual" y "como está disponible" con todas las fallas y sin
                      garantía de ningún tipo. IMPERIUM no ofrece ninguna
                      representación o garantía (expresa, implícita o legal) con
                      respecto al SERVICIO.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM no garantiza que sus servicios funcionen de forma
                      ininterrumpida o sin errores. El USUARIO también reconoce
                      que las comunicaciones electrónicas y las bases de datos
                      están sujetas a errores, manipulaciones y robos y que,
                      aunque IMPERIUM pondrá en práctica precauciones de
                      seguridad razonables para intentar evitar dichos sucesos,
                      no garantiza que no se produzcan.
                    </p>

                    {/* Actualización de los servicios o las condiciones de uso */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      IMPERIUM tiene la potestad de, sin previo aviso:
                    </p>
                    <ul className="list-disc list-inside">
                      <br />
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Complementar o hacer cambios en el presente ACUERDO.
                      </li>
                      <li className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        Modificar o retirar cualquier servicio o cualquier base
                        de datos, material, servicio o sistema de Imperium.
                      </li>
                    </ul>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Cualquier actualización de este ACUERDO se publicará en la
                      página web oficial (
                      <a
                        className="text-custom-purple"
                        href="https://imperium.cool/"
                      >
                        https://imperium.cool/
                      </a>
                      ) y se notificará vía correo electrónico. El USUARIO
                      reconoce que tiene la obligación de revisar y aceptar
                      cualquier actualización que ocurra en el futuro respecto a
                      este ACUERDO. IMPERIUM se compromete a proteger los
                      derechos de propiedad de los USUARIOS respecto a los
                      ACTIVOS DIGITALES en actualizaciones del ACUERDO.
                    </p>

                    {/* Respecto a la propiedad intelectual de los servicios */}

                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Toda la infraestructura y servicios digitales utilizados
                      por el USUARIO son de IMPERIUM. Esto significa que estos
                      están protegidos por las leyes aplicables de derechos de
                      autor y los tratados internacionales de derechos de autor,
                      que incluyen, entre otras cosas, las imágenes,
                      fotografías, animaciones, vídeo, audio, código, artículos,
                      texto y “applets”, incorporados en nuestras plataformas
                      digitales.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      De esta manera, cuando el USUARIO ingresa o utiliza
                      cualquiera de nuestras plataformas digitales, se le está
                      concediendo el derecho a que lo use con fines personales.
                      En este sentido, el USUARIO no podrá usar los servicios de
                      IMPERIUM para fines diferentes y no podrá utilizar
                      nuestras marcas y signos distintivos (como logos).
                      Cualquier uso de ellos por parte del USUARIO será un uso
                      no autorizado.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Adicionalmente, el USUARIO no podrá copiar o reproducir en
                      todo o en parte el contenido o el diseño de nuestra página
                      web o cualquier otra plataforma digital que sea propiedad
                      de IMPERIUM. El USUARIO tiene terminalmente prohibido
                      intentar reconfigurar, descomponer o utilizar ingeniería
                      inversa sobre nuestras plataformas digitales y/o el
                      contenido de los servicios prestados a través de IMPERIUM.
                    </p>
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Si en algún momento IMPERIUM adquiere conocimiento que el
                      USUARIO ha hecho uso de nuestra propiedad intelectual o de
                      nuestras marcas en contravía de lo que se ha indicado en
                      este ACUERDO, daremos por terminado su acceso a nuestros
                      servicios sin previo aviso, lo cual podría conllevar a la
                      pérdida de las fracciones adquiridas por el mismo.
                    </p>
                  </div>
                  {/* <!-- Modal footer --> */}
                  <div className="flex justify-end items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      onClick={closeModal}
                      type="button"
                      className="text-gray-400 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-custom-purple rounded-[4px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                      CERRAR
                    </button>
                    <button
                      onClick={handleAcceptTerms}
                      type="button"
                      className="text-white bg-custom-purple hover:bg-custom-purple-dark focus:ring-4 focus:outline-none focus:ring-custom-purple font-medium rounded-[4px] text-sm px-5 py-2.5 text-center"
                    >
                      ACEPTO
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalTerms;
