import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from "react";
import Cookies from "universal-cookie";

export const AuthContext = createContext();

const MY_AUTH_TOKEN = "tokenImperium";
const MY_AUTH_USERNAME = "usernameImperium";
const MY_AUTH_EMAIL = "emailImperium";
const MY_AUTH_USER_ID = "idUserImperium";

// const baseUrl =
//   process.env.NODE_ENV !== "production" ? "" : process.env.REACT_APP_API_URL;

export const AuthContextProvider = ({ children }) => {
  const cookies = useMemo(() => new Cookies(), []);
  const [isAuthenticated, setIsAuthenticated] = useState(
    cookies.get(MY_AUTH_TOKEN) ?? false
  );
  const [user, setUser] = useState({
    username: cookies.get(MY_AUTH_USERNAME) ?? null,
    email: cookies.get(MY_AUTH_EMAIL) ?? null,
    _id: cookies.get(MY_AUTH_USER_ID) ?? null,
    token: cookies.get(MY_AUTH_TOKEN) ?? null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const logout = useCallback(
    function () {
      cookies.remove(MY_AUTH_USER_ID, { path: "/" });
      cookies.remove(MY_AUTH_TOKEN, { path: "/" });
      cookies.remove(MY_AUTH_EMAIL, { path: "/" });
      cookies.remove(MY_AUTH_USERNAME, { path: "/" });

      setUser({
        username: null,
        email: null,
        _id: null,
        token: null,
      });

      setIsAuthenticated(false);
    },
    [cookies]
  );

  useEffect(() => {
    setIsLoading(true);

    const checkCookies = () => {
      if (!user.username || !user.email || !user._id || !user.token) {
        logout();
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };
    checkCookies();
  }, [logout, user._id, user.email, user.token, user.username, cookies]);

  const login = useCallback(
    async function (userId, token, email, username) {
      let date = new Date();
      date.setTime(date.getTime() + 86400 * 1000); // expires after 24 hours ( 1 hour = 3600 seconds)
      cookies.set(MY_AUTH_USER_ID, userId, { path: "/", expires: date });
      cookies.set(MY_AUTH_EMAIL, email, { path: "/", expires: date });
      cookies.set(MY_AUTH_USERNAME, username, { path: "/", expires: date });
      cookies.set(MY_AUTH_TOKEN, token, { path: "/", expires: date });

      setIsAuthenticated(true);
      setUser({
        username,
        email,
        _id: userId,
        token,
      });
    },
    [cookies]
  );

  const value = useMemo(
    () => ({
      login,
      logout,
      // user,
      username: user.username,
      email: user.email,
      id: user._id,
      token: user.token,
      isAuthenticated,
      isLoading,
      // }),[login, logout, isAuthenticated])
    }),
    [
      login,
      logout,
      isAuthenticated,
      user.username,
      user.email,
      user._id,
      isLoading,
      user.token,
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
