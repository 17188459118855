export const getPropertyData = (properties, property) => {
  return properties?.find( propertyName => propertyName?.propertyName === property)?.propertyObject?.data
}

export const getPropertyLink = (properties, property) => {
  return (
    properties?.find((propertyName) => propertyName?.propertyName === property)
      ?.propertyObject?.data[0]?.name || null
  );
};

