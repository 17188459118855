import React from 'react'
import Cookies from "universal-cookie"
import {useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import DepositLogsInfo from '../../components/DepositLogs/DepositLogsInfo'
import DepositLogsFiat from '../../components/DepositLogs/DepositLogsFiat'
import LoadingDot from '../ProjectsPage/Loading'
import paginate from '../../helpers/paginate'


const MY_AUTH_USER_ID = 'idUserImperium'
const MY_AUTH_TOKEN = "tokenImperium";
const DepositLogs = () => {
const cookies = new Cookies();
const [userID] = useState(cookies.get(MY_AUTH_USER_ID) ?? false)
const [token] = useState(cookies.get(MY_AUTH_TOKEN) ?? false)
const [depositLogs, setDepositLogs] = useState([])
const [loading, setLoading] = useState(true);
const [page,setPage] = useState(0);
const [dataLog, setDataLog] = useState([])


const baseUrl = process.env.REACT_APP_API_URL;
const depositLogUrl = `${baseUrl}/v1/deposits/?userId=${userID}`

useEffect(()=>{
  if(loading) return
    setDataLog(depositLogs[page])
},[loading,page,depositLogs])



useEffect(()=>{
  fetch(depositLogUrl, {
  method: 'GET', // data can be `string` or {object}!
  headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
})
  .then((response) => response.json())
  .then((data) => {
    console.log(data);
    setLoading(false)
    return setDepositLogs(paginate(data.allDepositsBalanceByUserId?.reverse()))
  }

  ).catch((err) =>{
    console.log(err);
    setDepositLogs([])
  })
},[depositLogUrl,token])

const handlePage = (index)=>{
  setPage(index)
}


const nextPage = () =>{
  setPage((oldPage) =>{
    let nextPage = oldPage + 1
    if(nextPage > depositLogs.length -1){
      nextPage = 0
    }
    return nextPage
  })
}
const prevPage = () =>{
  setPage((oldPage) =>{
    let prevPage = oldPage - 1
    if(prevPage < 0){
      prevPage = depositLogs.length - 1
    }
    return prevPage
  })
}

//Scroll to top
useEffect(() => {
  window.scrollTo(0, 0)
}, [])




return (
<div className='text-white  lg:p-8 min-h-screen bg-[#1C1B2D] '>
    <div className='p-2 sm:p-4 min-h-[60vh] w-full mx-auto 2xl:max-w-screen-xl'>
        <h2 className='text-center md:text-center text-2xl md:text-3xl font-bold'>Historial de depósitos de criptos</h2>
        <div className='h-[5rem] mt-8 bg-[#0D0D0D] text-lg  rounded-2xl   items-center  justify-around gap-4  hidden lg:flex '>
            <ul>
              <li>Fecha y hora</li>
            </ul>
            <ul>
              <li>Cripto</li>
            </ul>
            <ul>
              <li>Monto</li>
            </ul>
            <ul>
              <li>TxID</li>
            </ul>
            <ul>
              <li>TxID Verificado</li>
            </ul>
        </div>
        

        {loading ? (
        <div className='mt-36'>
          <LoadingDot />
        </div>
      ) : (
        <>
        {depositLogs === false ? <section>
        <div className='bg-[#0D0D0D] mt-4 flex justify-center text-center rounded-3xl p-4'>
          <div>
            <h2 className='py-2 text-xl'>Tu historial esta vacío</h2>
            <p className='text-[#959595] text-lg py-2 '>Comienza depositando en tu cuenta</p>
            <div className='my-4'>
              <NavLink to='/dashboard/deposit' className='text-black font-semibold text-base  py-1 px-8 bg-[#68E365] rounded-lg '>Depositar
              </NavLink>
            </div>
          </div>
        </div>
      </section> :
      <>
        <DepositLogsInfo logsInfo={dataLog} />
        <section className='flex justify-center flex-wrap mt-8  text-lg '>
          <button className='mx-4' onClick={prevPage}>
           <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
          </button>
          {depositLogs?.map((item, index)=>{
              return <button key={index} onClick={()=> handlePage(index)} className={`w-8 h-8 bg-custom-pink border-transparent rounded-md x cursor-pointer m-1 transition-all hover:bg-[#FF5262]  ${index === page ? 'bg-[#FF5262] text-white' : null}` }>
                {index + 1}
              </button>
          })}
           <button className='mx-4 ' onClick={nextPage}>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
          </button>
        </section>
        </>}
        </>
      )}
    </div>

        <div className='p-4 min-h-screen w-full mx-auto 2xl:max-w-screen-xl'>
        <h2 className='text-center md:text-center text-2xl md:text-3xl font-bold'>Historial de depósitos por transferencia bancaria</h2>
        <div className='h-[5rem] mt-8 bg-[#0D0D0D] text-lg rounded-2xl  gap-8 items-center  justify-around px-4 hidden lg:flex'>
            <ul>
              <li>Fecha y hora</li>
            </ul>
            <ul>
              <li>Moneda</li>
            </ul>
            <ul>
              <li>Monto</li>
            </ul>
            <ul>
              <li>Procesado</li>
            </ul>
        </div>
        

        {loading ? (
        <div className='mt-36'>
          <LoadingDot />
        </div>
      ) : (
        <>
        <DepositLogsFiat logsInfo={dataLog} />
        <section className='flex justify-center flex-wrap mt-8  text-lg'>
          <button className='mx-4' onClick={prevPage}>
           <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
          </button>
          {depositLogs?.map((item, index)=>{
              return <button key={index} onClick={()=> handlePage(index)} className={`w-8 h-8 bg-custom-pink border-transparent rounded-md x cursor-pointer m-1 transition-all hover:bg-[#FF5262]  ${index === page ? 'bg-[#FF5262] text-white' : null}` }>
                {index + 1}
              </button>
          })}
           <button className='mx-4 ' onClick={nextPage}>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
          </button>
        </section>
        </>
      )}
    </div>
    
</div>
)
}

export default DepositLogs








