import { Link } from "react-router-dom";
import LogoImperium from "../../Assets/Imgs/Logo.png";

const NotFound404 = () => {
  return (
    <div
      className={`flex flex-col justify-center align-center bg-custom-black h-screen w-screen flex justify-center items-center fixed z-20`}
    >
      <h1 className="text-7xl lg:text-[12rem] text-custom-purple font-poppins font-medium">
        404
      </h1>
      <h2 className="text-xl lg:text-3xl text-white font-anek-tamil mt-2 lg:-mt-0">
        Lo siento, ha ocurrido un error
      </h2>
      <p className="small-devices-280:text-xs lg:text-base font-poppins font-light text-custom-gray-light mb-8 lg:mb-5 xl:mb-8 ">
        Por favor, vuelve a intentarlo más tarde
      </p>
      <Link
        to={"/"}
        className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
      >
        Volver al inicio
      </Link>
      <img
        src={LogoImperium}
        alt="imperiumLogo"
        className={`rotate-[8deg] mt-5 small-devices-390:mt-20 small-devices-540:mt-10 md:mt-20 h-8 left-1/2`}
      />
    </div>
  );
};

export default NotFound404;
