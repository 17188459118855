import { Navigate } from "react-router-dom";

const PrivateRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? (
    <div>{children}</div>
  ) : (
    <Navigate to="/auth/login" replace={true} />
  );
};

export default PrivateRoute;
