import { Routes, Route } from "react-router-dom";
import { depositContextData } from ".././contexts/depositContext";

import Balance from "../pages/Dashboard/Balance";
import Deposit from "../pages/Dashboard/Deposit";
import NoFunds from "../pages/Dashboard/NoFunds";
import BankTransfer from "../pages/Dashboard/BankTransfer";
import DebitCredit from "../pages/Dashboard/DebitCredit";
import DepositLogs from "../pages/Dashboard/DepositLogs";
import StepOnetoDeposit from "../components/Deposits/StepOnetoDeposit";
import StepTwotoDeposit from "../components/Deposits/StepTwotoDeposit";
import StepThreetoDeposit from "../components/Deposits/StepThreetoDeposit";
import OtherCriptos from "../components/Deposits/OthersCriptos";
import NotFound404 from "../pages/NotFound404/NotFound404";
import { useState } from "react";
import Sidebar from "../layout/DashboardLayout/Sidebar";
import DashboardNavbar from "../components/Dashboard/DashboardNav";
import WithdrawFunds from "../pages/Dashboard/WithdrawFunds";


export const DashboardRouter = () => {
  const [depositValue, setDepositValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [criptoValue, setCriptoValue] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [blockchainValue, setBlockChainValue] = useState("");

  return (
    <depositContextData.Provider
      value={{
        depositValue,
        setDepositValue,
        addressValue,
        setAddressValue,
        criptoValue,
        setCriptoValue,
        unitValue,
        setUnitValue,
        blockchainValue,
        setBlockChainValue,
      }}
    >
      <DashboardNavbar />
      <Sidebar>
        <Routes>
          <Route index path="balance" element={<Balance />} />
          <Route path="deposit" element={<Deposit />} />
          <Route path="no-funds" element={<NoFunds />} />
          <Route path="deposit-step1" element={<StepOnetoDeposit />} />
          <Route path="deposit-step2" element={<StepTwotoDeposit />} />
          <Route path="deposit-step3" element={<StepThreetoDeposit />} />

          <Route path="deposit-logs" element={<DepositLogs />} />
          <Route path="bank-transfer" element={<BankTransfer />} />
          <Route path="withdraw-funds" element={<WithdrawFunds />} />
          <Route path="other-criptos" element={<OtherCriptos />} />
          <Route path="card-transfer" element={<DebitCredit />} />

          <Route path={"*"} element={<NotFound404 />} />
        </Routes>
      </Sidebar>
    </depositContextData.Provider>
  );
};
