import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ReactComponent as Padlock } from "../../../Assets/Icons/Padlock.svg";
import { ReactComponent as UserPlus } from "../../../Assets/Icons/UserPlus.svg";
import { ReactComponent as User } from "../../../Assets/Icons/User.svg";
import { ReactComponent as Mail } from "../../../Assets/Icons/Mail.svg";
// import { ReactComponent as Check } from "../../../Assets/Icons/Check.svg";
import { ReactComponent as ToolTip } from "../../../Assets/Icons/Tooltip.svg";
import ModalTerms from "../../../components/ModalTerms/ModalTerms";
import "./RegisterToolTip.css";
import { useAuthContext } from "../../../contexts/authContext";
import GoogleButton from "../../../components/Buttons/GoogleButton";
import LoaderButton from "../../../components/Loader/LoaderButton";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";
import Logo from "../../../components/Logo/Logo";

const baseUrl = process.env.REACT_APP_API_URL;

const schema = yup.object().shape({
  firstName: yup.string().trim().required("Ingresa tu nombre"),
  lastName: yup.string().trim().required("Ingresa tu apellido"),
  username: yup
    .string()
    .trim("Evita colocar espacios innecesarios")
    .strict(true)
    .required("Debes crear un nombre de usuario")
    .matches(
      /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?!.*[_.])$/,
      "El nombre de usuario no debe contener espacios ni caracteres especiales."
    )
    .test(
      "checkUserNameUnique",
      "Este usuario ya está en uso, elige otro",

      (username) =>
        fetch(`${baseUrl}/v1/user/account?username=${username}`)
          .then((res) => res.json())
          .then((data) => {
            return !data.dataUser;
          })
          .catch(() => {
            console.log("Server failed. Endpoint check if username exist");
            return false;
          })
    ),
  termsAndConditions: yup.boolean().required().isTrue(),
  email: yup
    .string()
    .trim("Evita colocar espacios innecesarios")
    .strict(true)
    .email("Correo electrónico inválido")
    .required("Ingresa un correo electrónico")
    .test(
      "checkEmailUnique",
      "Este correo electrónico ya se encuentra registrado.",
      (userEmail) =>
        fetch(`${baseUrl}/v1/user/account?userEmail=${userEmail.toLowerCase()}`)
          .then((res) => res.json())
          .then((data) => {
            return !data.dataUser;
          })
          .catch(() => {
            console.log("Server failed. Endpoint check if email exist");
            return false;
          })
    ),
  password: yup
    .string()
    .required("Ingresa una contraseña")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.* ).{8,}$/,
      "Tu contraseña debe tener mínimo 8 carácteres: alfanuméricos, especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios entre ellos"
    ),
  confirmPassword: yup
    .string()
    .required("Confirma tu contraseña")
    .oneOf([yup.ref("password"), null], "Ambas contraseñas deben ser iguales"),
});

const RegisterPage = () => {
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [isHiddenPasswordConfirm, setIsHiddenPasswordConfirm] = useState(true);
  const [errorMsgServices, setErrorMsgServices] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingExternal, setIsSubmittingExternal] = useState(false);
  const { login } = useAuthContext();

  let navigate = useNavigate();

  const handleDisplayErrorMessage = (error) => {
    console.log(error);
    setTimeout(() => {
      setIsSubmitting(false);
      setErrorMsgServices(error);
    }, 1000);
    setTimeout(() => setErrorMsgServices(null), 3000);
  };

  // !---------------------------MODAL --------------------------
  let [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const handleAcceptTerms = () => {
    setChecked(true);
    closeModal();
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleToggle = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handleToggleConfirm = () => {
    setIsHiddenPasswordConfirm(!isHiddenPasswordConfirm);
  };

  const {
    register,
    handleSubmit,
    // control,
    // setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleLoginByGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsSubmittingExternal(true);
      try {
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        };

        const responseGoogle = await fetch(
          process.env.REACT_APP_GOOGLE_URL,
          options
        );

        const userInfo = await responseGoogle.json();

        console.log("userInfo", userInfo);

        const url = `${baseUrl}/v1/auth/login?sourceName=Google`;

        const responseLogin = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            userEmail: userInfo.email,
            tokenGoogle: tokenResponse.access_token,
          }),
        });

        const data = await responseLogin.json();

        console.log("OUR API RESPONSE, ", data);

        setTimeout(() => {
          if (data.error === "Invalid data") {
            navigate(
              `/auth/register-external?email=${userInfo.email}&name=${userInfo.given_name}&lastname=${userInfo.family_name}&userId=${userInfo.sub}&fromLogin=true`
            );
          } else if (data.data) {
            const { token, userID, username, userEmail } = data.data;
            login(userID, token, userEmail, username);
          }
          setIsSubmittingExternal(false);
        }, 2000);
      } catch (e) {
        console.log("error CATCH, ", e);
        handleDisplayErrorMessage("Ha ocurrido un error, intenta de nuevo");
        setIsSubmittingExternal(false);
      }
    },
    onError: (errorResponse) => {
      console.log("error onError, ", errorResponse);
      handleDisplayErrorMessage("Ha ocurrido un error, intenta de nuevo");
    },
  });

  const onSubmit = async ({
    username,
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
  }) => {
    setErrorMsgServices(null);
    setIsSubmitting(true);

    const newUser = {
      timestamp: +new Date(),
      username: username,
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email.toLowerCase(),
      password: password,
      externalAccount: false,
      externalAccountName: null,
      externalID: null,
      isVerified: false,
    };

    const url = `${baseUrl}/v1/user/account?sourceName=Imperium`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(newUser),
      });

      const data = await response.json();

      if (data.message === "User Created") {
        const payload =
          process.env.REACT_APP_PAYLOAD === ""
            ? "http://localhost:3000"
            : process.env.REACT_APP_PAYLOAD;
        const urlAPI = process.env.REACT_APP_EMAIL_URL;
        const request = {
          destination: data.user.userEmail,
          emailCode: "confirmationEmail",
          payload: `${payload}/#/verified-account/${data.user.userEmail}/imperium`,
        };

        const responseVerificationAccount = await fetch(urlAPI, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=utf-8",
            key: process.env.REACT_APP_EMAIL_KEY,
          },
          body: JSON.stringify(request),
        });

        const dataVerificationAccount =
          await responseVerificationAccount.json();

        console.log(
          "email sent? , dataVerificationAccount",
          dataVerificationAccount
        );
      }

      setTimeout(() => {
        login(
          data.user._id,
          data.token,
          data.user.userEmail,
          data.user.username
        );
        navigate(`/created-account/${data.user.userEmail}`);
        setIsSubmitting(false);
      }, 2000);
    } catch (error) {
      console.log("error, ", error);
      setTimeout(() => {
        setIsSubmitting(false);
        setErrorMsgServices("Ha ocurrido un error, intenta de nuevo");
      }, 1000);
      setTimeout(() => setErrorMsgServices(null), 3000);
    }
  };

  return (
    <div className="min-h-screen flex">
      <ModalTerms
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        handleAcceptTerms={handleAcceptTerms}
      />

      {/* -----------------------------   Start image section  ----------------------------*/}
      <WelcomeLoginImage />
      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}

      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-2/5">
          <h1 className="lg:block text-3xl md:text-4xl font-extrabold mb-6 mt-5 font-anek-tamil">
            Crea tu cuenta
          </h1>

          <p className="hidden lg:block font-poppins font-light text-custom-gray-light mb-8 lg:mb-5 xl:mb-8 ">
            Sé uno de los primeros ciudadanos del Web3
          </p>

          {/* -----------------------------   Start form section  ----------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col lg:flex-row w-full gap-x-4 lg:mb-4 mb-auto">
              {/* --------FirstName---------- */}
              <div
                className={`w-full ${
                  errors.lastName ? "mb-0" : "mb-2"
                } lg:w-1/2 lg:mb-auto`}
              >
                {errors.firstName && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.firstName?.message}
                  </div>
                )}

                {!errors.firstName && errors.lastName && (
                  <div className="hidden lg:block my-2 text-custom-red font-anek-tamil">
                    <br />
                  </div>
                )}

                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                    <User className="text-red-500  w-6 h-6" />
                  </div>
                  <input
                    {...register("firstName")}
                    type="text"
                    id="firstName"
                    className={` font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                    placeholder="Nombre"
                  />
                  <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                    {/* {!errors.firstName && touchedFields.firstName && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )} */}

                    {/* {!errors.firstName && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )} */}
                  </div>
                </div>
              </div>

              {/* --------LastName---------- */}

              <div
                className={`w-full ${
                  errors.username ? "mb-0" : "mb-2"
                } lg:w-1/2 lg:mb-auto`}
              >
                {errors.lastName && (
                  <div className="my-2 text-custom-red font-anek-tamil">
                    {errors.lastName?.message}
                  </div>
                )}

                {!errors.lastName && errors.firstName && (
                  <div className="hidden lg:block my-2 text-custom-red font-anek-tamil">
                    <br />
                  </div>
                )}
                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                    <User className="text-red-500  w-6 h-6" />
                  </div>
                  <input
                    {...register("lastName")}
                    type="text"
                    id="lastName"
                    className={` font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                    placeholder="Apellido"
                    required=""
                  />
                  <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                    {/* {!errors.lastName && touchedFields.lastName && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )} */}

                    {/* {!errors.lastName && (
                      <Check className="text-custom-gray-light--input w-6 h-6" />
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            {/* --------Username---------- */}

            <div className="mb-2 lg:mb-4 ">
              {errors.username && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.username?.message}
                </div>
              )}

              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <UserPlus className="text-red-500  w-7 h-7" />
                </div>

                <input
                  {...register("username")}
                  type="text"
                  id="username"
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Crea el nombre de tu usuario"
                  required=""
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  {/* {!errors.username && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}

                  {/* {!errors.username && touchedFields.username && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}
                </div>
              </div>
            </div>

            {/* --------Email---------- */}

            <div className="mb-2 lg:mb-4 ">
              {errors.email && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.email?.message}
                </div>
              )}

              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Mail className="text-red-500  w-7 h-7" />
                </div>

                <input
                  {...register("email")}
                  type="text"
                  id="email"
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Correo electrónico"
                  required=""
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  {/* {!errors.email && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}
                  {/* {!errors.email && touchedFields.email && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}
                </div>
              </div>
            </div>

            {/* --------Password---------- */}

            <div className="mb-2 lg:mb-4 ">
              {errors.password && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.password?.message}
                </div>
              )}

              <div className="relative ">
                <div
                  className="items-center toolTipRegister absolute z-10 -left-12 top-0 bottom-0 hidden lg:flex"
                  data-tooltip="Tu contraseña debe tener mínimo 8 carácteres: alfanuméricos, especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios entre ellos"
                >
                  <div className="relative">
                    <ToolTip className="cursor-pointer toolTipRegister" />
                  </div>
                </div>
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Padlock className="text-custom-gray w-6 h-6" />
                </div>

                <input
                  {...register("password")}
                  type={isHiddenPassword ? "password" : "text"}
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full px-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Contraseña"
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0 cur">
                  {isHiddenPassword ? (
                    <AiOutlineEyeInvisible
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handleToggle}
                    />
                  ) : (
                    <AiOutlineEye
                      className="text-custom-gray-light--input w-6 h-6  cursor-pointer"
                      onClick={handleToggle}
                    />
                  )}
                </div>

                <div className="flex absolute inset-y-0 items-center right-14 right-0">
                  {/* {!errors.password && touchedFields.password && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}

                  {/* {!errors.password && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}
                </div>
              </div>
            </div>

            {/* --------Confirm password---------- */}
            <div className="mb-2 lg:mb-4 ">
              {errors.confirmPassword && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {errors.confirmPassword?.message}
                </div>
              )}

              <div className="relative">
                <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                  <Padlock className="text-custom-gray w-6 h-6" />
                </div>

                <input
                  {...register("confirmPassword")}
                  type={isHiddenPasswordConfirm ? "password" : "text"}
                  className={`font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 2xl:py-5 border text-md rounded-lg block w-full px-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-white focus:ring-custom-purple focus:border-custom-purple`}
                  placeholder="Confirmar contraseña"
                />

                <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                  {isHiddenPasswordConfirm ? (
                    <AiOutlineEyeInvisible
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handleToggleConfirm}
                    />
                  ) : (
                    <AiOutlineEye
                      className="text-custom-gray-light--input w-6 h-6 cursor-pointer"
                      onClick={handleToggleConfirm}
                    />
                  )}
                </div>

                <div className="flex absolute inset-y-0 items-center right-14 right-0">
                  {/* {!errors.confirmPassword && touchedFields.confirmPassword && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}

                  {/* {!errors.confirmPassword && (
                    <Check className="text-custom-gray-light--input w-6 h-6" />
                  )} */}
                </div>
              </div>
            </div>

            {/* {!errors.password && !touchedFields.password && (
              <p className="lg:hidden text-sm mb-2 font-anek-tamil font-normal text-custom-gray-light--terms">
                Tu contraseña debe tener mínimo 8 carácteres: alfanuméricos,
                especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios
                entre ellos.
              </p>
            )} */}

            {!errors.password && (
              <p className="lg:hidden text-sm mb-2 font-anek-tamil font-normal text-custom-gray-light--terms">
                Tu contraseña debe tener mínimo 8 carácteres: alfanuméricos,
                especiales (&_-/$#@), mayúsculas y minúsculas, sin espacios
                entre ellos.
              </p>
            )}

            {/* --------Terms and conditions---------- */}

            <div className="flex justify-start mb-6 lg:mb-3 xl:mb-6">
              <div className="flex items-center">
                <input
                  {...register("termsAndConditions")}
                  checked={checked}
                  onChange={handleChange}
                  id="checked-checkbox"
                  type="checkbox"
                  className="mr-3 w-4 h-4 text-custom-purple bg-gray-100 rounded border-gray-300 focus:ring-custom-purple dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <label
                  htmlFor="terms"
                  className="font-anek-tamil font-normal text-custom-gray-light--terms"
                >
                  He leido y acepto los{" "}
                  <label
                    onClick={openModal}
                    className="font-anek-tamil font-normal text-custom-purple hover:underline"
                  >
                    términos y condiciones
                  </label>
                </label>
              </div>
            </div>

            {/* --------Submit button---------- */}

            <div className="flex flex-col justify-center xl:mt-2 2xl:mt-16">
              {errorMsgServices && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {"Ha ocurrido un error, intenta de nuevo"}
                </div>
              )}

              <LoaderButton
                isSubmitting={isSubmitting}
                isDisabled={!checked || isSubmitting}
                text={"Crear nueva cuenta"}
                type={"submit"}
              />
            </div>
          </form>

          {/* -----------------------------   Finish form section  ----------------------------*/}
          {/* -----------------------------   Start Social networks section  ----------------------------*/}

          <div className="mt-6 small-devices-390:mt-10 mb-5 md:mt-20 flex justify-center flex-col items-center">
            <p className="font-poppins font-normal text-custom-gray-light--text mb-3 sm:mb-8 xl:mb-8 text-lg">
              Crea tu cuenta con
            </p>

            <div className="text-custom-gray-light mb-3 text-lg">
              <GoogleButton
                isSubmitting={isSubmittingExternal}
                handleLoginByGoogle={handleLoginByGoogle}
              />
            </div>

            <label
              htmlFor="terms"
              className="font-anek-tamil font-normal text-lg text-base text-custom-gray-light--terms"
            >
              ¿Ya tienes una cuenta?{" "}
              <Link
                to={"/auth/login"}
                className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
              >
                Ingresa aquí
              </Link>
            </label>
            <Logo size={"small"} />
          </div>
        </div>
      </div>
      {/* -----------------------------   Finish Social networks section  ----------------------------*/}
      {/* -----------------------------   Finish login section  ----------------------------*/}
    </div>
  );
};

export default RegisterPage;
