import "./whatsappButton.scss";
import { ReactComponent as Whatsapp } from "../../../Assets/Icons/Whatsapp.svg";

const WhatsappButton = () => {
  return (
    <div className="floating-button__container">
      <a
        href="https://wa.me/message/PYXMG3SKHSLSP1"
        rel="noreferrer"
        target="_blank"
        className="floating-button__button show hover:bg-[#128c7e]"
      >
        <Whatsapp className="floating-button__icon text-white fill-current w-9 h-9" />
      </a>
    </div>
  );
};

export default WhatsappButton;
