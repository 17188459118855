import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Padlock } from "../../../Assets/Icons/Padlock.svg";
import { ReactComponent as User } from "../../../Assets/Icons/User.svg";
import { useAuthContext } from "../../../contexts/authContext";
import WelcomeLoginImage from "../../../components/WelcomeLoginImage/WelcomeLoginImage";
import LoaderButton from "../../../components/Loader/LoaderButton";
import GoogleButton from "../../../components/Buttons/GoogleButton";
import Logo from "../../../components/Logo/Logo";

const baseUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingExternal, setIsSubmittingExternal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsgServices, setErrorMsgServices] = useState(null);
  const navigate = useNavigate();
  // let location = useLocation();
  const { login } = useAuthContext();

  const handleToggleHiddenPassword = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handleDisplayErrorMessage = (error) => {
    setTimeout(() => {
      setIsSubmitting(false);
      setErrorMsgServices(error);
    }, 1000);
    setTimeout(() => setErrorMsgServices(null), 3000);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  console.log(errors);
  const handleLoginByGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsSubmittingExternal(true);
      try {
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        };

        const responseGoogle = await fetch(
          process.env.REACT_APP_GOOGLE_URL,
          options
        );

        const userInfo = await responseGoogle.json();

        console.log("userInfo", userInfo);

        const url = `${baseUrl}/v1/auth/login?sourceName=Google`;

        const responseLogin = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            userEmail: userInfo.email,
            tokenGoogle: tokenResponse.access_token,
          }),
        });

        const data = await responseLogin.json();

        console.log("OUR API RESPONSE, ", data);

        setTimeout(() => {
          if (data.error === "Invalid data") {
            navigate(
              `/auth/register-external?email=${userInfo.email}&name=${userInfo.given_name}&lastname=${userInfo.family_name}&userId=${userInfo.sub}&fromLogin=true`
            );
          } else if (data.data) {
            const { token, userID, username, userEmail } = data.data;

            login(userID, token, userEmail, username);
          }
          setIsSubmittingExternal(false);
        }, 2000);
      } catch (e) {
        console.log("error CATCH, ", e);
        handleDisplayErrorMessage("Ha ocurrido un error, intenta de nuevo");
        setIsSubmittingExternal(false);
      }
    },
    onError: (errorResponse) => {
      console.log("error onError, ", errorResponse);
      handleDisplayErrorMessage("Ha ocurrido un error, intenta de nuevo");
    },
  });

  // -------------------------------------------------------

  const onSubmit = async ({ email, password }) => {
    setErrorMsg(null);
    setErrorMsgServices(null);

    if (email.length === 0 || password.length === 0) {
      setErrorMsg("Debes completar los campos");
      setTimeout(() => setErrorMsg(null), 3000);
      return;
    }

    setIsSubmitting(true);

    const url = `${baseUrl}/v1/auth/login?sourceName=Imperium`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          userEmail: email.toLowerCase().trim(),
          password,
        }),
      });

      const data = await response.json();
      console.log(data);

      setTimeout(() => {
        setIsSubmitting(false);

        if (!data.ok) {
          setErrorMsg("Contraseña o usuario incorrecto");
          setTimeout(() => setErrorMsg(null), 3000);
          return;
        }

        login(
          data.data.userID,
          data.data.token,
          data.data.userEmail,
          data.data.username
        );
      }, 2000);
    } catch (error) {
      console.log("error, ", error);
      setTimeout(() => {
        setIsSubmitting(false);
        setErrorMsgServices("Ha ocurrido un error, intenta de nuevo");
      }, 1000);
      setTimeout(() => setErrorMsgServices(null), 3000);
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* <LoadingPage isLoading={isLoading}/> */}
      {/* -----------------------------   Start image section  ----------------------------*/}

      <WelcomeLoginImage />

      {/* -----------------------------   Finish image section  ----------------------------*/}

      {/* -----------------------------   Start login section  ----------------------------*/}

      <div className="bg-custom-black h-auto w-full lg:w-1/2  flex justify-center items-center relative">
        <div className="w-4/5 small-devices-540:w-3/5 sm:w-3/5 md:w-2/5 lg:w-3/4 xl:w-3/5 2xl:w-2/5">
          <h1 className="text-3xl md:text-4xl font-extrabold mb-6 font-anek-tamil">
            Iniciar sesión
          </h1>

          <p className="hidden lg:block font-poppins font-light text-custom-gray-light mb-8 lg:mb-5 xl:mb-8 ">
            Entra y revisa lo nuevo en Imperium
          </p>

          {/* -----------------------------   Start form section  ----------------------------*/}
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMsg && (
              <div className="my-2 text-custom-red font-anek-tamil">
                {errorMsg}
              </div>
            )}
            <div className="mb-4 relative">
              <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                <User className="text-red-500  w-6 h-6" />
              </div>

              <input
                {...register("email")}
                type="text"
                id="email"
                className="font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 xl:py-5 border text-md rounded-lg block w-full pl-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-custom-purple focus:ring-custom-purple focus:border-custom-purple"
                placeholder="Correo electrónico"
              />
            </div>

            <div className="mb-4 sm:mb-6 relative">
              <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none left-0">
                <Padlock className="text-custom-gray w-6 h-6" />
              </div>

              <input
                {...register("password")}
                type={isHiddenPassword ? "password" : "text"}
                autoComplete="new-password"
                className="font-anek-tamil font-normal py-3 sm:py-5 lg:py-4 xl:py-5 border text-md rounded-lg block w-full px-12 bg-custom-gray-darker border-transparent placeholder-custom-gray-light--input text-custom-purple focus:ring-custom-purple focus:border-custom-purple"
                placeholder="Contraseña"
              />

              <div className="flex absolute inset-y-0 items-center pr-5 right-0">
                {isHiddenPassword ? (
                  <AiOutlineEyeInvisible
                    className="text-custom-gray-light--input w-6 h-6"
                    onClick={handleToggleHiddenPassword}
                  />
                ) : (
                  <AiOutlineEye
                    className="text-custom-gray-light--input w-6 h-6"
                    onClick={handleToggleHiddenPassword}
                  />
                )}
              </div>
            </div>

            <div className="flex justify-end mb-6 lg:mb-3 xl:mb-6">
              <Link
                to={"/auth/forgot-password"}
                className="font-anek-tamil font-normal text-custom-purple hover:underline"
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </div>

            <div className="flex flex-col justify-center xl:mt-8 2xl:mt-16">
              {errorMsgServices && (
                <div className="my-2 text-custom-red font-anek-tamil">
                  {"Ha ocurrido un error, intenta de nuevo"}
                </div>
              )}

              <LoaderButton
                isSubmitting={isSubmitting}
                isDisabled={isSubmitting}
                text={"Iniciar sesión"}
              />
            </div>
          </form>

          {/* -----------------------------   Finish form section  ----------------------------*/}
          {/* -----------------------------   Start Social networks section  ----------------------------*/}

          <div className="mt-6 small-devices-360:mt-8 md:mt-20 flex justify-center flex-col items-center">
            <p className="font-poppins font-normal text-custom-gray-light--text mb-3 sm:mb-8 xl:mb-8 text-lg">
              Iniciar sesión con
            </p>

            <div className="text-custom-gray-light mb-3 text-lg">
              <GoogleButton
                isSubmitting={isSubmittingExternal}
                handleLoginByGoogle={handleLoginByGoogle}
              />
            </div>

            <Link
              to={"/auth/register"}
              className="font-anek-tamil font-normal text-lg text-base text-custom-purple hover:underline"
            >
              Crear una nueva cuenta
            </Link>

            <Logo size={"small"} />
          </div>
        </div>
      </div>
      {/* -----------------------------   Finish Social networks section  ----------------------------*/}
      {/* -----------------------------   Finish login section  ----------------------------*/}
    </div>
  );
};

export default LoginPage;
