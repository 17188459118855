// const baseUrl = process.env.REACT_APP_API_URL;

export const getFranctionsByUser = async (userID, token) => {
  
  // const url = `${baseUrl}/v1/computeUserDashboardData?userId=${userID}`
  const url = `https://www.wolframcloud.com/obj/christianp/Imperium/Prototypes/Explorer/APIs/Dashboard-01`

    try {

      const bodyData = {
        token,
        env: process.env.REACT_APP_ENVIRONMENT === "production" ? "PRD" : "TST",
        userID,
      }

      const options = {
        method: 'POST',
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Authorization': `Bearer ${token}`
        // },
        body: JSON.stringify(bodyData),
      }

      // const options = {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`
      //   }
      // }
  
      const response = await fetch(url, options)
  
      const data = await response.json() 

      return data
    } catch (error) {
      return null
    }
  }