import React, { useState } from 'react'
import { useEffect } from 'react'
import QRBitcoin from '../../Assets/Imgs/QRCodeBitcoin.png'
import QRCardano from '../../Assets/Imgs/QRCodeCardano.png'
import QREtherium from '../../Assets/Imgs/QRCodeEthereum.png'

const imageMapping = {
  '18gmPJLjqbCwgj1Tz2XB4uG4ottNPcyAAA': QRBitcoin,
  '0x0b3593a3f61409f6c0d2036a7780e9d03b452bef': QREtherium,
  'addr1vyfp04wmqecdnl8llpvzj222ar0a9lvssze0ysac8suc2wghl6qu7': QRCardano
};

const QRImage = ({value}) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    switch (value) {
      case '18gmPJLjqbCwgj1Tz2XB4uG4ottNPcyAAA':
      case '0x0b3593a3f61409f6c0d2036a7780e9d03b452bef':
      case 'addr1vyfp04wmqecdnl8llpvzj222ar0a9lvssze0ysac8suc2wghl6qu7':
        setImage(imageMapping[value]);
        break;
      default:
        setImage(null);
    }
  }, [value]);

  return (
    <img src={image} className='w-full rounded-md' alt={`${value} coin`}  />
  )
}

export default QRImage
