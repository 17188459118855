import React from 'react'

import preview from '../../Assets/Imgs/preview.png'
import { Link, useNavigate } from "react-router-dom"

import {getPropertyLink,getPropertyData} from '../../helpers/propertiesProject'

import {handleSetTagStylesByStatusRelease,statusButton,statusButtonText,handleSetStatusValue} from './status'
import { ReactComponent as Eye } from "../../Assets/Icons/Eye.svg";
import { ReactComponent as Cart } from "../../Assets/Icons/Cart.svg";
import { ReactComponent as Pizza } from "../../Assets/Icons/Pizza.svg";
import { ReactComponent as Dollar } from "../../Assets/Icons/Dollar.svg";




const Card = ({projects}) => {


const navigate = useNavigate()



return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 animate__animated animate__fadeIn   items-center gap-8 sm:items-stretch   '>
        {projects.map((projectsInfo)=>{
            const {image} = projectsInfo
            const price = getPropertyData(projectsInfo?.properties, 'Price per fraction')
            const name = getPropertyData(projectsInfo?.properties, 'Name')
            const category = getPropertyLink(projectsInfo?.properties, 'Categoría')
            const id = projectsInfo?._id
            const releaseStatus = projectsInfo.releaseStatus
        

            return  <article  key={id} className='bg-[#20212B] py-1 max-w-[17.5rem] z-20 card_shadow overflow-hidden text-white rounded-xl h-auto mx-auto  flex flex-col'>
                <div className='flex justify-between px-2 py-3 text-sm'>
                <p className={`${handleSetTagStylesByStatusRelease(releaseStatus)}`}>{handleSetStatusValue(releaseStatus)}</p>
                    <p className={`${category ? 'bg-[#50515D]' : null} px-5 rounded-3xl`}>{category}</p>
                </div>
                <Link to={releaseStatus.statusCode === 3 ? '/' : `project/${id}`} className={`object-cover overflow-hidden w-full h-full ${releaseStatus.statusCode === 3 ? 'cursor-default' : ''}`}>
                <img draggable="false" src={`${ image ? image : preview}`} className={`${releaseStatus.statusCode === 3   ? 'grayscale-[90%]' : ''} ${ releaseStatus.statusCode !== 3 ? 'hover:scale-[1.05]' : ''} object-cover  max-w-full  transition-all duration-300   w-[17.75rem] z-10  h-[16.25rem]`} alt={name} />
                </Link>
                <div className='p-2'>
                    <p className='overflow-hidden whitespace-nowrap text-ellipsis'>{name}</p>
                    <div className='flex justify-between text-sm   gap-2 whitespace-nowrap'>
                        <p>Precio por fracción</p>
                        <p>Fracciones disponibles</p>
                    </div>
                    <div className='flex justify-between text-base]'>
                    {/* <p className={`${ releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? 'text-custom-gray-light--input' : 'text-white'}`} >{`${releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? '-' : finalPrice}`}</p> */}
                    {/* <p className={`${ releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? 'text-custom-gray-light--input' : 'text-white'}`} >{`${releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? '-' : finalPrice}`}</p> */}
                        {/* <p className={`${ releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? 'text-custom-gray-light--input' : 'text-white'}`}>{`${releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? '-' : <Pizza/> {projectsInfo.remainingFractions}}`}</p> */}
                       
                        <div className={`${ releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? 'text-custom-gray-light--input' : 'text-white'}`}>
                            {
                                releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? (
                                    '-'
                                ) : (
                                    <div className='flex gap-2'>
                                        <Dollar className='w-6 h-6'/> {price}
                                    </div>
                                )
                            }
                        </div>
                       
                       
                        <div className={`${ releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? 'text-custom-gray-light--input' : 'text-white'}`}>
                            {
                                releaseStatus.statusCode === 3 || releaseStatus.statusCode === 4 ? (
                                    '-'
                                ) : (
                                    <div className='flex gap-2'>
                                        <Pizza className='w-5 h-5'/> {projectsInfo.remainingFractions}
                                    </div>
                                )
                            }
                        </div>
                        </div>
                    { 
                        (releaseStatus.statusCode === 1 || releaseStatus.statusCode === 2 || releaseStatus.statusCode === 4) && 
                        
                        <button onClick={() => navigate(`project/${id}`)} 

                        className={`${statusButton(releaseStatus)}`}>
                        
                        {
                            releaseStatus.statusCode === 1 ? <Cart
                            className="w-4 h-4"
                            /> : <Eye
                            className="w-4 h-4"
                            />
                        }
                        
                        <span>
                            {statusButtonText(releaseStatus)}
                        </span>
                    </button>
                 }
                 { releaseStatus.statusCode === 3 && 
                    <div className='py-1 w-full flex justify-center'>
                         <p className='text-[#F97575] font-semibold text-sm mt-4 rounded-md  bg-transparent'>¡Prepárate para su lanzamiento!</p>
                    </div>
                }
                </div>
            </article>
        })}
    </div>
  )
}

export default Card